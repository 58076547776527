import { Injectable, EventEmitter } from "@angular/core";
import { HttpService } from "./api/http.service";
import { environment } from "src/environments/environment";
import { OnBoardingStatus } from "../interfaces/supplier";
import { SupplierService } from "./supplier.service";

@Injectable()
export class CommonService {
  ExitEvent = new EventEmitter();

  constructor(private http: HttpService) {}

  exit(event) {
    this.ExitEvent.emit(event);
  }

  // getDictionary(code = null): Promise<any> {
  //   let url = `${environment.gateway}/dropdown/dictionary`

  //   if (code) {
  //     url = `${url}/${code}`
  //   }
  //   return this.http.GetPromise(url)
  // }

  checkTokenExpired() {
    const url = `${environment.gateway}/token/isExpired`;
    return this.http.GetPromise(url);
  }

  // logoutToken() {
  //   const url = `${environment.gateway}/token/invalid`
  //   return this.http.GetPromise(url)
  // }

  isBuyer() {
    if (environment.role === "buyer") {
      return true;
    }
    return false;
  }
  getSupplierAttribute(data: any): Promise<any> {
    const url = `${environment.gateway}/b2c/getSupplierAttribute`;
    return this.http.PostPromise(url, data);
  }

  logoutToken() {
    const url = `${environment.gateway}/token/invalid`;
    return this.http.GetPromise(url);
  }
}
