import {
  Component,
  OnInit,
  HostListener,
  ViewChild,
  ElementRef,
  Input,
} from "@angular/core";
import { BuyerACLPermissionService } from "src/app/modules/buyer/services/buyerACLPermission.service";
import { ReportService } from "src/app/modules/buyer/services/report.service";
import { BuyerRoleACLButton, RoleName } from "src/app/interfaces/buyer";
import {
  createReportDTO,
  SRCMReportSearchDTO,
} from "src/app/interfaces/reportSRCM";
import { CommonService } from "src/app/services/common.service";
import { AuthService } from "src/app/services/auth/auth.service";
import { MetricsReportDto } from "src/app/interfaces/metricsReport";
import { metricsReportList } from "../../../../shared/shared";
import { environment } from "src/environments/environment";
import { LoadingUrlMapping } from "src/app/interfaces/mapping";
import { LoadingService } from "src/app/services/loading.service";
import * as moment from "moment";
import { NewAuditTrailReportDto } from "src/app/interfaces/newAuditTrailReport";
import { DictionaryService } from "src/app/services/dictionary.service";
import { DSReportDTO } from "src/app/interfaces/DiversityAndSustainabilityReport";
import { CSSReportDTO } from "src/app/interfaces/cssReport";

enum DropdownTermType {
  fiscalYear = "fiscalYear",
  geographicalUnit = "geographicalUnit",
  month = "month",
  country = "country",
  companyCode = "companyCode",
  spendCategory = "spendCategory",
  onboardedFY = "onboardedFY",
  onboardedMonth = "onboardedMonth",
  setUpCountry = "setUpCountry",
  businessOrganization = "businessOrganization",
  spendCommodityGroup = "spendCommodityGroup",
  ApprovalStatus = "ApprovalStatus",
  OnboardingStatus = "OnboardingStatus",
  estimatedSpend = "estimatedSpend",
}

enum EndDateStatus {
  unEndDate = "Please input End Date",
  endAboveStart = "end date shall be after or equal to the start date",
}

@Component({
  selector: "app-report-srcm",
  templateUrl: "./report-srcm.component.html",
  styleUrls: ["./report-srcm.component.sass"],
})
export class ReportSRCMComponent implements OnInit {
  @Input() enableClick = true;
  isShowFilter = false;
  isShowMetricsFilter = false;
  isShowRawDataFilter = false;
  isShowAuditTrailFilter = false;
  isShowCSSFilter = false;
  isShowDrop = false;
  isEmptyChoice = false;
  isShowSubMenu = false;
  isValidated = false;
  isValidatedOnboard = false;
  isShowMigrateDate = false;
  dto: SRCMReportSearchDTO = createReportDTO();
  metrics: MetricsReportDto = {};
  css: CSSReportDTO = {};
  rawdataReport: MetricsReportDto = {};
  audit: NewAuditTrailReportDto = {};
  dsReport: DSReportDTO = {};
  MetricsTypeList = metricsReportList;
  dropdownTermType = DropdownTermType;
  dataSource = {};
  endDateText = EndDateStatus.unEndDate;
  endDateTextOnboard = EndDateStatus.unEndDate;
  endDateTextForTask = EndDateStatus.unEndDate;
  @ViewChild("reportDrop")
  reportDrop: ElementRef;
  @ViewChild("reportForm")
  reportForm: ElementRef;
  @ViewChild("reportIcon")
  reportIcon: ElementRef;

  CompanyCode: any;
  SupplierGeographical: any;
  DSSupplierGeographical: any;
  DictBusinessOrganization: any;
  SupplierCountry: any;

  currentCountryDropDownList: any[];
  currentCountryDropDownListItems: any[];
  currentCountryDropDownListCode: any[];
  currentCompanyDropDownList: any[];
  migrateNewSupplierList: any[];
  currentCountryCodeList: any[];
  currentCompanyCodeList: any[];
  SpendCommodityGroup: any;
  estimatedSpend: any;
  approvalStatus: any;
  onboardingStatus: any;

  constructor(
    private buyerACLPermissionService: BuyerACLPermissionService,
    private reportService: ReportService,
    private dictionaryService: DictionaryService,
    private auth: AuthService,
    private loadingService: LoadingService
  ) {}

  async ngOnInit() {
    this.currentCompanyDropDownList = [];
    const data = await this.dictionaryService.getDictionary("Report_SRCM");
    if (data) {
      data.map((t) => {
        this.dataSource[t.Code] = t.Items;
      });
    }

    this.approvalStatus = [
      { Code: "Approve", Text: "Approve" },
      { Code: "Reject", Text: "Reject" },
      { Code: "", Text: "Blank" },
    ];

    this.onboardingStatus = [
      { Code: "Onboarded", Text: "Onboarded" },
      { Code: "Pending", Text: "Pending" },
      { Code: "Terminated", Text: "Terminated" },
    ];

    const dicts = await this.dictionaryService.getDictionary(
      "requestor-details"
    );
   
    this.SpendCommodityGroup = dicts.filter(
      (dict) => dict.Code === "SpendCommodityGroup"
    )[0].Items;
    
    this.CompanyCode = dicts.filter(
      (dict) => dict.Code === "CompanyCode"
    )[0].Items;
    this.SupplierGeographical = dicts
      .filter(
        (dict) => dict.Code === "SupplierGeographicalFor S&CM Reporter"
      )[0]
      .Items.filter((item) => item.Items.length > 0)
      .sort((a, b) => a.Text.charCodeAt(0) - b.Text.charCodeAt(0));
    // For DS Report dropdownList without Iberia
    this.DSSupplierGeographical = this.SupplierGeographical.filter(
      (item) => item.Code !== "19"
    );
    this.DictBusinessOrganization = dicts.filter(
      (dict) => dict.Code === "BusinessOrganization"
    )[0].Items;
    this.SupplierCountry = dicts.filter(
      (dict) => dict.Code === "SupplierCountry"
    )[0].Items;
    this.migrateNewSupplierList = [
      { Code: "1", Text: "Migrated" },
      { Code: "0", Text: "New supplier" },
    ];


    const dicts1 = await this.dictionaryService.getDictionary(
      "requestor-form"
    );
     let estimate1 = dicts1.filter(
      (dict) => dict.Code === "EstimatedSpend"
    )[0].Items;
    estimate1.forEach(item => {
    if (item.Code === "14") {
        item.Text = "Between $500,000 and $1,000,000";
    }
    if (item.Code === "15") {
        item.Text = "Over $1,000,000";
    }
    });
     const estimate2 = dicts1.filter(
      (dict) => dict.Code === "EstimatedSpend_v2"
    )[0].Items;
     const estimate3 = dicts1.filter(
      (dict) => dict.Code === "EstimatedSpend_v3"
    )[0].Items;
    this.estimatedSpend = this.combineEstimateItems(estimate1,estimate2,estimate3);
  }

  combineEstimateItems = (items1, items2, items3) => {
    const itemMap = new Map();
    const addItemToMap = (item) => {
        const existingItem = itemMap.get(item.Text);
        if (existingItem) {
            if (!existingItem.Code.includes(item.Code)) {
                existingItem.Code.push(item.Code);
            }
        } else {
            itemMap.set(item.Text, { Text: item.Text, Code: [item.Code] });
        }
    };
    items1.forEach(addItemToMap);
    items2.forEach(addItemToMap);
    items3.forEach(addItemToMap);
    return Array.from(itemMap.values());
  };

  initCountryCodeDropDown() {
    this.currentCountryDropDownList = [];
    this.currentCountryDropDownListItems = [];
    this.currentCountryDropDownListCode = [];
    if (this.css.geographicalUnit) {
      this.currentCountryDropDownList = this.SupplierGeographical.filter(
        (geo: any) => {
          return geo.Code === this.css.geographicalUnit;
        }
      );
    }
    if (this.metrics.geographicalUnit) {
      this.currentCountryDropDownList = this.SupplierGeographical.filter(
        (geo: any) => {
          return geo.Code === this.metrics.geographicalUnit;
        }
      );
    }
    if (this.isShowRawDataFilter) {
      if (this.rawdataReport.geographicalUnit) {
        this.currentCountryDropDownList = this.SupplierGeographical.filter(
          (geo: any) => {
            return geo.Code === this.rawdataReport.geographicalUnit;
          }
        );
      }
    }
    if (this.isShowAuditTrailFilter) {
      if (this.audit.geographicalUnit) {
        this.currentCountryDropDownList = this.SupplierGeographical.filter(
          (geo: any) => {
            return geo.Code === this.audit.geographicalUnit;
          }
        );
      }
    }

    this.currentCountryDropDownListItems =
      this.currentCountryDropDownList[0].Items;
    this.currentCountryDropDownList.forEach((ev) => {
      ev.Items.forEach((item) => {
        if (item.checked) {
          item.checked = false;
        }
        this.currentCountryDropDownListCode.push(item.Code);
      });
    });
    if (this.metrics.setUpCountry) {
      if (this.metrics.setUpCountry.length === 0) {
        this.metrics.companyCode = [];
      }
      this.initCompanyCodeDropDown();
    }
    if (this.isShowRawDataFilter) {
      if (this.rawdataReport.setUpCountry) {
        if (this.rawdataReport.setUpCountry.length === 0) {
          this.rawdataReport.companyCode = [];
        }
        this.initCompanyCodeDropDown();
      }
    }
    if (this.isShowAuditTrailFilter) {
      if (this.audit.setUpCountry) {
        if (this.audit.setUpCountry.length === 0) {
          this.audit.companyCode = [];
        }
        this.initCompanyCodeDropDown();
      }
    }
    
  }

  initCompanyCodeDropDown() {
    this.currentCompanyDropDownList = [];
    this.CompanyCode.forEach((ev: any) => {
      if (this.isShowAuditTrailFilter) {
        this.audit.setUpCountry.forEach((tar: any) => {
          if (ev.Code === tar) {
            ev.Items.forEach((Text: any) => {
              this.currentCompanyDropDownList.push(Text);
            });
          }
        });
      } 
       else if (this.isShowRawDataFilter) {
        this.rawdataReport.setUpCountry.forEach((tar: any) => {
          if (ev.Code === tar) {
            ev.Items.forEach((Text: any) => {
              this.currentCompanyDropDownList.push(Text);
            });
          }
        });
      } else {
        this.metrics.setUpCountry.forEach((tar: any) => {
          if (ev.Code === tar) {
            ev.Items.forEach((Text: any) => {
              this.currentCompanyDropDownList.push(Text);
            });
          }
        });
      }
    });
  }

  changeGeographicalUnitList(Code) {
    if (this.metrics.setUpCountry !== undefined) {
      if (this.metrics.setUpCountry.length !== 0) {
        this.metrics.setUpCountry = [];
      }
    }
    if (this.metrics.companyCode !== undefined) {
      if (this.metrics.companyCode.length !== 0) {
        this.metrics.companyCode = [];
      }
    }
    if (this.rawdataReport.setUpCountry !== undefined) {
      if (this.rawdataReport.setUpCountry.length !== 0) {
        this.rawdataReport.setUpCountry = [];
      }
    }
    if (this.rawdataReport.companyCode !== undefined) {
      if (this.rawdataReport.companyCode.length !== 0) {
        this.rawdataReport.companyCode = [];
      }
    }
    if (this.isShowAuditTrailFilter) {
      if (this.audit.setUpCountry !== undefined) {
        if (this.audit.setUpCountry.length !== 0) {
          this.audit.setUpCountry = [];
        }
      }
      if (this.audit.companyCode !== undefined) {
        if (this.audit.companyCode.length !== 0) {
          this.audit.companyCode = [];
        }
      }
    }

    this.initCountryCodeDropDown();
  }

  initMigrateDate() {
    if (this.audit.isMigrated.includes("1")) {
      this.isShowMigrateDate = true;
    } else {
      this.isShowMigrateDate = false;
      this.audit.migratestartDate = null;
      this.audit.migrateendDate = null;
      this.endDateText = EndDateStatus.unEndDate;
    }
  }

  get hasSRCMPerssion() {
    const bibdaFormIndex =
      this.buyerACLPermissionService.buttonPermissions.indexOf(
        BuyerRoleACLButton.ReportSRCMButton
      );
    return bibdaFormIndex !== -1;
    // return true
  }

  get showAPReportDownload() {
    const apReportDownloadIndex =
      this.buyerACLPermissionService.buttonPermissions.indexOf(
        BuyerRoleACLButton.APReportDownloadButton
      );
    return apReportDownloadIndex !== -1;
  }

  get showSystemAdminReportDownload() {
    return (
      this.auth.passport.buyer.RoleCode === RoleName.SystemAdmin ||
      this.auth.passport.buyer.RoleCode === RoleName.BuyerAdmin
    );
  }

  get showSystemAdminAndSCM() {
    const result =
      this.auth.passport.buyer.RoleCode === RoleName.SystemAdmin ||
      this.auth.passport.buyer.RoleCode === RoleName.BuyerAdmin ||
      this.auth.passport.buyer.RoleCode === RoleName.SourcingCategoryManagement;
    return result;
  }

  get showMetricsReportDownload() {
    const metricsReportDownloadIndex =
      this.buyerACLPermissionService.buttonPermissions.indexOf(
        BuyerRoleACLButton.APReportDownloadButton
      );
    if (
      metricsReportDownloadIndex !== -1 ||
      this.auth.passport.buyer.RoleCode === RoleName.SystemAdmin
    ) {
      return true;
    } else {
      return false;
    }
  }

  get showCssReportDownload() {
    const cssReportDownloadIndex =
      this.buyerACLPermissionService.buttonPermissions.indexOf(
        BuyerRoleACLButton.CSSReportDownloadButton
      );
    if (
      cssReportDownloadIndex !== -1 ||
      this.auth.passport.buyer.RoleCode === RoleName.SourcingCategoryManagement
    ) {
      return true;
    } else {
      return false;
    }
  }

  saveSectionEvent(e, type) {
    this.isEmptyChoice = false;
    if (this.isShowFilter) {
      this.dto[type] = e;
    }
    if (this.isShowMetricsFilter) {
      this.metrics[type] = e;
    }
    if (this.isShowCSSFilter) {
      this.css[type] = e;
    }
    if (this.isShowRawDataFilter) {
      this.rawdataReport[type] = e;
    }
    if (this.isShowAuditTrailFilter) {
      this.audit[type] = e;
    }
    
  }

  @HostListener("document:keydown", ["$event"])
  documentClick(evt) {
    if (this.reportIcon && evt.keyCode !== 13) {
      if (this.reportIcon.nativeElement.contains(evt.target)) {
        return;
      }
      this.isShowDrop = false;
    }
    if (this.reportForm) {
      if (this.reportForm.nativeElement.contains(evt.target)) {
        return;
      }
      this.isShowFilter = false;
    }
    if(evt.keyCode == 13 && evt.srcElement.nodeName === 'APP-REPORT-SRCM') {
      this.isShowDrop = !this.isShowDrop;
    }
    // this.isEmptyChoice = true   bug 914422
    this.dto = createReportDTO();
  }

  search() {
    const timeZone = -new Date().getTimezoneOffset();
    this.dto.timeZone = timeZone;
    const saveUrl = `${environment.gateway}/reportSRCM`;
    this.loadingService.openLoading(saveUrl);
    this.reportService.generate(this.dto).then((data) => {
      const csv = "\uFEFF" + data.data;
      if (window.navigator.msSaveBlob && window.navigator.msSaveOrOpenBlob) {
        const blob = new Blob([csv], { type: "application/csv" });
        window.navigator.msSaveBlob(blob, "SCM_Report.csv");
      } else {
        const file = new File([csv], "SCM_Report.csv", {
          type: "application/csv",
        });
        const link = document.createElement("a");
        link.style.display = "none";
        document.body.appendChild(link);
        if (link.download !== undefined) {
          link.setAttribute("href", URL.createObjectURL(file));
          link.setAttribute("download", "SCM_Report.csv");
          link.click();
        }
        document.body.removeChild(link);
        this.loadingService.closeLoading();
      }
      this.isEmptyChoice = true;
      this.dto = createReportDTO();
    });
    this.closetable();
  }

  downloadAPReport() {
    const saveUrl = `${environment.gateway}/apReportDownload`;
    this.loadingService.openLoading(saveUrl);
    const timeZone = -new Date().getTimezoneOffset();
    const data = {
      rolecode: this.auth.passport.buyer.RoleCode,
      timeZone: timeZone
    }
    this.reportService.downloadAPReport(data).then((data) => {
      const csv = "\uFEFF" + data.data;
      // let file = new File([csv], 'AP_Report.csv', { type: 'application/csv' })
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        const blob = new Blob([csv], { type: "application/csv" });
        window.navigator.msSaveOrOpenBlob(blob, "AP_Report.csv");
      } else {
        const link = document.createElement("a");
        link.style.display = "none";
        document.body.appendChild(link);
        if (link.download !== undefined) {
          const file = new File([csv], "AP_Report.csv", {
            type: "application/csv",
          });
          link.setAttribute("href", URL.createObjectURL(file));
          link.setAttribute("download", "AP_Report.csv");
          link.click();
        }
        document.body.removeChild(link);
        this.loadingService.closeLoading();
      }
    });
  }

  downloadAuditReport() {
    if (this.audit.isMigrated && this.audit.isMigrated.includes("1")) {
      if (
        !this.validateDate(
          this.audit.migratestartDate,
          this.audit.migrateendDate,
          this.audit.taskstartDate,
          this.audit.taskendDate
        )
      ) {
        return;
      }
    } else {
      if (
        !this.validaeTaskDate(this.audit.taskstartDate, this.audit.taskendDate)
      ) {
        return;
      }
    }
    if (this.audit.isMigrated && this.audit.isMigrated.length === 2) {
      this.audit.isMigrated = "";
    } else if (this.audit.isMigrated && this.audit.isMigrated.length !== 2) {
      this.audit.isMigrated = this.audit.isMigrated.toString();
    }
    const saveUrl = `${environment.gateway}${LoadingUrlMapping.metricsReport}`;
    this.loadingService.openLoading(saveUrl);

    this.audit.taskstartDate = new Date(
      this.audit.taskstartDate.getTime() -
        new Date(this.audit.taskstartDate.getTime()).getTimezoneOffset() * 60000
    );
    this.audit.taskendDate = new Date(
      new Date(this.audit.taskendDate).getTime() +
        (24 - this.audit.taskendDate.getTimezoneOffset() / 60) *
          60 *
          60 *
          1000 -
        1
    );
    this.audit.roleCode = this.auth.passport.buyer.RoleCode;
    this.reportService.auditTrailReportDownload(this.audit).then((data) => {
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(data, "Audit_Trail_Report.xlsx");
      } else {
        const link = document.createElement("a");
        const blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        link.setAttribute("href", window.URL.createObjectURL(blob));
        link.setAttribute("download", "Audit_Trail_Report.xlsx");
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
      this.closetable();
      this.loadingService.closeLoading();
    });
    this.audit.taskendDate = new Date(
      new Date(this.audit.taskendDate).getTime() +
        (this.audit.taskendDate.getTimezoneOffset() / 60) * 60 * 60 * 1000
    );
  }

  downloadRawDataReport() {
    // if (!this.validateRawData()) {
    //   return;
    // }
    const saveUrl = `${environment.gateway}${LoadingUrlMapping.rawDataReport}`;
    this.loadingService.openLoading(saveUrl);
    // this.rawdataReport.startDate = new Date(
    //   this.rawdataReport.startDate.getTime() -
    //     new Date(this.rawdataReport.startDate.getTime()).getTimezoneOffset() *
    //       60000
    // );
    // this.rawdataReport.endDate = new Date(
    //   this.rawdataReport.endDate.getTime() -
    //     new Date(this.rawdataReport.endDate.getTime()).getTimezoneOffset() *
    //       60000
    // );
    this.reportService
      .downloadRawDataReport(this.rawdataReport)
      .then((data) => {
        // for IE
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(data, "Raw_Data_Report.xlsx");
        } else {
          const link = document.createElement("a");
          const blob = new Blob([data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          link.setAttribute("href", window.URL.createObjectURL(blob));
          link.setAttribute("download", "Raw_Data_Report.xlsx");
          link.style.visibility = "hidden";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
        this.closetable();
        this.loadingService.closeLoading();
      });
  }

  downloadChangeAvanadeCodeReport() {
    const saveUrl = `${environment.gateway}${LoadingUrlMapping.changeAvanadeCodeReport}`;
    this.loadingService.openLoading(saveUrl);
    this.reportService.downloadChangeAvanadeCodeReport().then((data) => {
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(
          data,
          "Change_AvanadeCompanyCode_Report.xlsx"
        );
      } else {
        const link = document.createElement("a");
        const blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        link.setAttribute("href", window.URL.createObjectURL(blob));
        link.setAttribute("download", "Change_AvanadeCompanyCode_Report.xlsx");
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        this.loadingService.closeLoading();
      }
    });
  }

  downloadDSReport() {
    if (
      (this.dsReport.createdStartDate &&
        this.dsReport.createdEndDate &&
        !this.validateDSDate(
          this.dsReport.createdStartDate,
          this.dsReport.createdEndDate
        )) ||
      (this.dsReport.onboardStartDate &&
        this.dsReport.onboardEndDate &&
        !this.validateDSDateOnboard(
          this.dsReport.onboardStartDate,
          this.dsReport.onboardEndDate
        ))
    ) {
      return;
    }
    const saveUrl = `${environment.gateway}${LoadingUrlMapping.dsReport}`;
    this.loadingService.openLoading(saveUrl);
    this.reportService.downloadDSReport(this.dsReport).then((data) => {
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(data, "DS_Report.xlsx");
      } else {
        const link = document.createElement("a");
        const blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        link.setAttribute("href", window.URL.createObjectURL(blob));
        link.setAttribute("download", "DS_Report.xlsx");
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
      this.closetable();
      this.loadingService.closeLoading();
    });
  }

  validateDSDate(startDate, endDate) {
    this.isValidated = true;
    if (startDate && endDate) {
      const date1 = startDate.getTime();
      const date2 = endDate.getTime();
      if (date2 < date1) {
        this.endDateText = EndDateStatus.endAboveStart;
        this.dsReport.createdEndDate = null;
        return false;
      }
    }
    return true;
  }

  validateDSDateOnboard(startDate, endDate) {
    this.isValidatedOnboard = true;
    if (startDate && endDate) {
      const date1 = startDate.getTime();
      const date2 = endDate.getTime();
      if (date2 < date1) {
        this.endDateTextOnboard = EndDateStatus.endAboveStart;
        this.dsReport.onboardEndDate = null;
        return false;
      }
    }
    return true;
  }

  validateDS(startDate, endDate, onboardStartDate, onboardEndDate) {
    this.isValidatedOnboard = true;
    if (startDate && endDate && onboardStartDate && onboardEndDate) {
      const date1 = startDate.getTime();
      const date2 = endDate.getTime();
      const date3 = onboardEndDate.getTime();
      const date4 = onboardStartDate.getTime();
      if (date2 < date1 && date4 < date3) {
        this.endDateTextOnboard = EndDateStatus.endAboveStart;
        this.endDateText = EndDateStatus.endAboveStart;
        this.dsReport.onboardEndDate = null;
        this.dsReport.createdEndDate = null;
        return false;
      }
    }
    return true;
  }
  showMetricsFilter() {
    this.isShowMetricsFilter = true;
    this.isShowDrop = false;
    this.isShowSubMenu = false;
  }
  showCssFilter() {
    this.isShowCSSFilter = true;
    this.isShowDrop = false;
    this.isShowSubMenu = false;
    this.css.geographicalUnit = [];
    this.css.setUpCountry = [];
    this.css.companyCode = [];
    this.css.country = [];
    this.css.businessOrganization = [];
    this.css.spendCommodityGroup = [];
    this.css.estimatedSpend = [];
    this.css.ApprovalStatus = [];
    this.css.OnboardingStatus = [];
    this.css.startDate = undefined;
    this.css.endDate = undefined;
  }
  showRawDataFilter() {
    this.isShowRawDataFilter = true;
    this.isShowDrop = false;
    this.isShowSubMenu = false;
  }
  showAuditTrailFilter() {
    this.isShowAuditTrailFilter = true;
    this.isShowDrop = false;
    this.isShowSubMenu = false;
  }

 

  closetable() {
    this.isValidated = false;
    this.isValidatedOnboard = false;
    this.isShowFilter = false;
    this.isShowCSSFilter = false;
    this.isShowMetricsFilter = false;
    this.isShowRawDataFilter = false;
    this.isShowAuditTrailFilter = false;
    this.isEmptyChoice = true;
    this.isShowMigrateDate = false;
    this.endDateText = EndDateStatus.unEndDate;
    this.endDateTextForTask = EndDateStatus.unEndDate;
    this.changeCheckedToFalse(this.audit as any);
    this.changeCheckedToFalse(this.metrics as any);
    this.changeCheckedToFalse(this.dsReport as any);
    this.changeCheckedToFalse(this.rawdataReport as any);
    this.metrics = {};
    this.dsReport = {};
    this.audit = {};
    this.rawdataReport = {};
    this.currentCountryDropDownList = [];
    this.currentCountryDropDownListItems = [];
    this.currentCountryDropDownListCode = [];
    this.currentCompanyDropDownList = [];
  }

  changeCheckedToFalse(targetSource: any) {
    if (targetSource.setUpCountry) {
      this.CompanyCode.forEach((ev: any) => {
        targetSource.setUpCountry.forEach((tar: any) => {
          if (ev.Code === tar) {
            ev.Items.forEach((Text: any) => {
              if (Text.checked) {
                Text.checked = false;
              }
            });
          }
        });
      });
    }
  }
  downloadMetricsReport() {
    if (!this.validate()) {
      return;
    }
    const saveUrl = `${environment.gateway}${LoadingUrlMapping.metricsReport}`;
    this.loadingService.openLoading(saveUrl);
    this.metrics.startDate = new Date(
      this.metrics.startDate.getTime() -
        new Date(this.metrics.startDate.getTime()).getTimezoneOffset() * 60000
    );
    this.metrics.endDate = new Date(
      this.metrics.endDate.getTime() -
        new Date(this.metrics.endDate.getTime()).getTimezoneOffset() * 60000
    );
    this.metrics.rolecode = this.auth.passport.buyer.RoleCode
    this.reportService.downloadMetricsReport(this.metrics).then((data) => {
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(data, "Metrics_Report.xlsx");
      } else {
        const link = document.createElement("a");
        const blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        link.setAttribute("href", window.URL.createObjectURL(blob));
        link.setAttribute("download", "Metrics_Report.xlsx");
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
      this.closetable();
      this.loadingService.closeLoading();
    });
  }  
  
  downloadCssReport() {
    const saveUrl = `${environment.gateway}${LoadingUrlMapping.cssReport}`;
    this.loadingService.openLoading(saveUrl);
   
    if (this.css.startDate !== undefined) {
      this.css.startDate = new Date(
        this.css.startDate.getTime() -
          new Date(this.css.startDate.getTime()).getTimezoneOffset() * 60000
      );
    }
    
    if (this.css.endDate !== undefined) {
      this.css.endDate = new Date(
        this.css.endDate.getTime() -
          new Date(this.css.endDate.getTime()).getTimezoneOffset() * 60000
      );
    }
    this.css.rolecode = this.auth.passport.buyer.RoleCode;
    this.css.estimatedSpend = this.css.estimatedSpend.reduce((acc, group) => {
        return acc.concat(group.map(item => [item]));
    }, []);
   this.reportService.downloadCssReport(this.css).then((data) => {
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(data, "CSS_Report.xlsx");
      } else {
        const link = document.createElement("a");
        const blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        link.setAttribute("href", window.URL.createObjectURL(blob));
        link.setAttribute("download", "CSS_Report.xlsx");
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        this.closetable();
        this.loadingService.closeLoading();
      }
    });
  }

  validateDate(startDate, endDate, taskstartDate, taskendDate) {
    this.isValidated = true;
    if (!startDate || !endDate || !taskstartDate || !taskendDate) {
      if (!startDate || JSON.stringify(startDate) === "null") {
        this.audit.migratestartDate = null;
      }
      if (!endDate || JSON.stringify(endDate) === "null") {
        this.audit.migrateendDate = null;
        this.endDateText = EndDateStatus.unEndDate;
      }
      if (!taskstartDate || JSON.stringify(taskstartDate) === "null") {
        this.audit.taskstartDate = null;
      }
      if (!taskendDate || JSON.stringify(taskendDate) === "null") {
        this.audit.taskendDate = null;
        this.endDateTextForTask = EndDateStatus.unEndDate;
      }
      return false;
    }
    if (JSON.stringify(endDate) === "null") {
      this.endDateText = EndDateStatus.unEndDate;
      this.audit.migrateendDate = null;
      return false;
    }
    if (JSON.stringify(taskendDate) === "null") {
      this.endDateTextForTask = EndDateStatus.unEndDate;
      this.audit.taskendDate = null;
      return false;
    }
    if (JSON.stringify(startDate) === "null") {
      this.audit.migratestartDate = null;
      return false;
    }
    if (JSON.stringify(taskstartDate) === "null") {
      this.audit.taskstartDate = null;
      return false;
    }
    const date1 = startDate.getTime();
    const date2 = endDate.getTime();
    const date3 = taskstartDate.getTime();
    const date4 = taskendDate.getTime();
    if (date2 < date1 && date4 < date3) {
      this.audit.migrateendDate = null;
      this.audit.taskendDate = null;
      this.endDateText = EndDateStatus.endAboveStart;
      this.endDateTextForTask = EndDateStatus.endAboveStart;
      return false;
    }
    if (date2 < date1) {
      this.audit.migrateendDate = null;
      this.endDateText = EndDateStatus.endAboveStart;
      return false;
    }
    if (date4 < date3) {
      this.audit.taskendDate = null;
      this.endDateTextForTask = EndDateStatus.endAboveStart;
      return false;
    }
    return true;
  }
  validaeTaskDate(startDate, endDate) {
    this.isValidated = true;
    if (!startDate || !endDate) {
      if (!startDate || JSON.stringify(startDate) === "null") {
        this.audit.taskstartDate = null;
      }
      if (!endDate || JSON.stringify(endDate) === "null") {
        this.audit.taskendDate = null;
        this.endDateTextForTask = EndDateStatus.unEndDate;
      }
      return false;
    }
    if (
      JSON.stringify(startDate) === "null" &&
      JSON.stringify(endDate) === "null"
    ) {
      this.audit.taskstartDate = null;
      this.endDateTextForTask = EndDateStatus.unEndDate;
      this.audit.taskendDate = null;
      return false;
    }
    if (JSON.stringify(endDate) === "null") {
      this.endDateTextForTask = EndDateStatus.unEndDate;
      this.audit.taskendDate = null;
      return false;
    }
    if (JSON.stringify(startDate) === "null") {
      this.audit.taskstartDate = null;
      return false;
    }
    const date1 = startDate.getTime();
    const date2 = endDate.getTime();
    if (date2 < date1) {
      this.audit.taskendDate = null;
      this.endDateTextForTask = EndDateStatus.endAboveStart;
      return false;
    }
    return true;
  }

  validate() {
    this.isValidated = true;
    if (!this.metrics.startDate || !this.metrics.endDate) {
      if (
        !this.metrics.startDate ||
        JSON.stringify(this.metrics.startDate) === "null"
      ) {
        this.metrics.startDate = null;
      }
      if (
        !this.metrics.endDate ||
        JSON.stringify(this.metrics.endDate) === "null"
      ) {
        this.metrics.endDate = null;
        this.endDateText = EndDateStatus.unEndDate;
      }
      return false;
    }
    if (
      JSON.stringify(this.metrics.endDate) === "null" &&
      JSON.stringify(this.metrics.startDate) === "null"
    ) {
      this.endDateText = EndDateStatus.unEndDate;
      this.metrics.endDate = null;
      this.metrics.startDate = null;
      return false;
    }
    if (JSON.stringify(this.metrics.endDate) === "null") {
      this.endDateText = EndDateStatus.unEndDate;
      this.metrics.endDate = null;
      return false;
    }
    if (JSON.stringify(this.metrics.startDate) === "null") {
      this.metrics.startDate = null;
      return false;
    }
    const date1 = this.metrics.startDate.getTime();
    const date2 = this.metrics.endDate.getTime();
    if (date2 < date1) {
      this.metrics.endDate = null;
      this.endDateText = EndDateStatus.endAboveStart;
      return false;
    }
    return true;
  }
  validateRawData() {
    this.isValidated = true;
    if (!this.rawdataReport.startDate || !this.rawdataReport.endDate) {
      if (
        !this.rawdataReport.startDate ||
        JSON.stringify(this.rawdataReport.startDate) === "null"
      ) {
        this.rawdataReport.startDate = null;
      }
      if (
        !this.rawdataReport.endDate ||
        JSON.stringify(this.rawdataReport.endDate) === "null"
      ) {
        this.rawdataReport.endDate = null;
        this.endDateText = EndDateStatus.unEndDate;
      }
      return false;
    }
    if (
      JSON.stringify(this.rawdataReport.endDate) === "null" &&
      JSON.stringify(this.rawdataReport.startDate) === "null"
    ) {
      this.endDateText = EndDateStatus.unEndDate;
      this.rawdataReport.endDate = null;
      this.rawdataReport.startDate = null;
      return false;
    }
    if (JSON.stringify(this.rawdataReport.endDate) === "null") {
      this.endDateText = EndDateStatus.unEndDate;
      this.rawdataReport.endDate = null;
      return false;
    }
    if (JSON.stringify(this.rawdataReport.startDate) === "null") {
      this.rawdataReport.startDate = null;
      return false;
    }
    const date1 = this.rawdataReport.startDate.getTime();
    const date2 = this.rawdataReport.endDate.getTime();
    if (date2 < date1) {
      this.rawdataReport.endDate = null;
      this.endDateText = EndDateStatus.endAboveStart;
      return false;
    }
    return true;
  }
  changeGUList(Code) {
    
    if (this.dto.geographicalUnit && this.isShowFilter) {
      this.dto.country = [];
      this.dto.componyCode = [];
      const geoCode = this.SupplierGeographical.filter(
        (item) => item.Code === Code
      );
      
      geoCode.map((list) => (this.currentCountryDropDownList = list.Items));
    }
    if (this.css.geographicalUnit && this.isShowCSSFilter) {
      this.css.country = [];
      this.css.companyCode = [];
      const geoCode = this.SupplierGeographical.filter(
        (item) => item.Code === Code
      );
      
      geoCode.map((list) => (this.currentCountryDropDownList = list.Items));
    }
    this.initCountryDropDown();
  }

  initCountryDropDown() {
    this.currentCountryDropDownList = [];
    this.currentCountryCodeList = [];
    // init usable CountryList in dropdown
    if (this.dto.country && this.isShowFilter) {
      
      this.SupplierGeographical.forEach((geo: any) => {
        if (this.dto.geographicalUnit.includes(geo.Code)) {
          geo.Items.forEach((item) => {
            this.currentCountryDropDownList.push(item);
            this.currentCountryCodeList.push(item.Code);
          });
        }
      });
    }
   
    if (this.css.country && this.isShowCSSFilter) {
      this.SupplierGeographical.forEach((geo: any) => {
        if (this.css.geographicalUnit.includes(geo.Code)) {
          geo.Items.forEach((item) => {
            this.currentCountryDropDownList.push(item);
            this.currentCountryCodeList.push(item.Code);
          });
        }
      });
    }
   
    this.initCompanyDropDown();
  }
  changeCountryCode() {
    // filter Selected companyCode when country change
    if (this.dto.componyCode && this.dto.componyCode.length !== 0) {
      this.dto.componyCode = this.dto.componyCode.filter((item) => {
        return this.currentCompanyCodeList.includes(item);
      });
    }
    this.initCompanyDropDown();
  }

  initCompanyDropDown() {
    this.currentCompanyDropDownList = [];
    this.currentCompanyCodeList = [];
    // init usable CompanyCodeList in dropdown
    if (this.dto.country && this.isShowFilter) {
      this.CompanyCode.forEach((company: any) => {
        if (this.dto.country.includes(company.Code)) {
          company.Items.forEach((item: any) => {
            const it: any = {};
            it.Code = item.Code;
            it.Text = item.Text;
            it.checked = this.dto.componyCode.includes(item.Code);
            this.currentCompanyDropDownList.push(it);
            this.currentCompanyCodeList.push(it.Code);
          });
        }
      });
    }
  }
}
