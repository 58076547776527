import { Component, OnInit, ViewChild } from "@angular/core";
import {
  RequestorDetailsInfo,
  RequestForm,
  RequestFormInfo,
} from "src/app/interfaces/requestForm";
import { OnBoardingService } from "../../../services/onboarding.service";
import { CommonService } from "src/app/services/common.service";
import { BuyerService } from "src/app/services/buyer.service";
import { ActivatedRoute } from "@angular/router";
import {
  initChangeRequestMapping,
  initAuditChangeRequestDetail,
  ChangeRequestType,
  PaymentTermsItem,
  initMultiplePaymentTermsCRItem,
} from "src/app/interfaces/changeRequest";
import { BuyerChangeRequestItem } from "src/app/interfaces/buyer";
import * as moment from "moment";
import { AuthService } from "src/app/services/auth/auth.service";
import { BuyerChangeRequestService } from "../../../services/buyerChangeRequest.service";
import { environment } from "src/environments/environment";
import { LoadingUrlMapping } from "src/app/interfaces/mapping";
import { LoadingService } from "src/app/services/loading.service";
import { BuyerCommonService } from "../../../services/buyerCommonService";
import { ComponentConfig } from "src/app/dynamic-components/ComponentConfig";
import { MetadataService } from "src/app/services/metadata.service";
import { DynamicContext } from "src/app/dynamic-components/interfaces/DynamicContext";
import { DynamicFormComponent } from "src/app/dynamic-components/components/dynamic-form/dynamic-form.component";
import { FormGroup, Validators } from "@angular/forms";
import {
  DialogService,
  DialogType,
  ButtonCommands,
} from "src/app/services/dialog.service";
import { DictionaryService } from "src/app/services/dictionary.service";
import { cloneDeep } from "src/app/utils";

enum PaymentTermsItemKey {
  NonStandardType = "HasNonStandardPaymentTerms",
  TermPayment = "TermPayment",
  AgreementAttachments = "AgreementAttachments",
  ApproveAttachments = "ApproveAttachments",
  ProcurementApproval = "ProcurementApproval",
}

@Component({
  selector: "app-payment-term",
  templateUrl: "./payment-term.component.html",
  styleUrls: ["./payment-term.component.sass"],
})
export class PaymentTermComponent implements OnInit {
  isEmptyChoice: boolean;
  currentCountryDropDownList: any;
  currentSelect: any;
  paymentTerm: any = "";
  dictSpendCategoryGroup: any;
  supplierCode: any;
  SupplierGeographical: any;
  dictSpendCommodityGroup: any;
  comment: any;
  isValidated: boolean;
  termPaymentCode: string;
  context: DynamicContext;
  previousPaymentTermsList: string;
  multiplePaymentTermsConfig: ComponentConfig[] = [];
  rand =Math.round((Math.random()*10000)).toString();
  // get requestorDetailsInfo(): RequestorDetailsInfo {
  //   return this.onBoardingService.supplierModel.RequestForm.RequestorDetailsInfo
  // }

  get requestForm(): RequestFormInfo {
    return this.onBoardingService.supplierModel.RequestForm.RequestFormInfo;
  }
  showTooltip: boolean;
  @ViewChild("dynamicForm") dynamicForm: DynamicFormComponent;
  constructor(
    private route: ActivatedRoute,
    private onBoardingService: OnBoardingService,
    private commonService: CommonService,
    private dictionaryService: DictionaryService,
    private authService: AuthService,
    private buyerChangeRequest: BuyerChangeRequestService,
    private loadingService: LoadingService,
    private buyerCommonService: BuyerCommonService,
    private metadataService: MetadataService,
    private dialogService: DialogService,
    private buyerService: BuyerService
  ) {}

  async ngOnInit() {
    this.loadingService.openLoading("");
    this.isEmptyChoice = false;
    this.isValidated = false;
    this.route.params.subscribe((data) => {
      this.supplierCode = data.supplierCode;
    });

    this.onBoardingService.supplierCode = this.supplierCode;
    await this.onBoardingService.loadSupplierRequestForm().then(async () => {
      // this.currentSelect = this.requestForm.SupplierCountry
      const dictionary =
        this.dictionaryService.getDictionaryFromCache("requestor-form");
      this.termPaymentCode = await this.buyerCommonService.getTermPaymentCode();
      this.dictSpendCommodityGroup = dictionary.find(
        (dict) => dict.Code === this.termPaymentCode
      ).Items;
    });
    await this.onBoardingService.loadSupplierProfile();
    this.previousPaymentTermsList = cloneDeep(this.requestForm.PaymentTermList);

    this.context = new DynamicContext();
    await this.buyerCommonService.SetContextValue(this.context);

    // Match standard payment term
    const result: any = await this.buyerService.getPaymentTermList({
      companyCode:
        this.onBoardingService.supplierModel.RequestForm.RequestorDetailsInfo
          .CompanyCode,
      geographicalUnit:
        this.onBoardingService.supplierModel.Mapping.geographicalUnit,
      category:
        this.onBoardingService.supplierModel.RequestForm.RequestorDetailsInfo
          .SpendCategoryGroup,
      spendCommodityGroup:
        this.onBoardingService.supplierModel.RequestForm.RequestorDetailsInfo
          .SpendCommodityGroup,
      enterpriseType:
        this.onBoardingService.supplierModel.SupplierProfile
          .SupplierOrganization.EnterpriseType,
    });
    if (result && result.isSuccess) {
      const standardPaymentTerms = result.data;
      this.context.set("standardPaymentTerms", standardPaymentTerms);

      if (
        this.onBoardingService.supplierModel.RequestForm.RequestFormInfo
          .PaymentTermList
      ) {
        this.onBoardingService.supplierModel.RequestForm.RequestFormInfo.PaymentTermList.forEach(
          (paymentTermItem: any) => {
            const standardPaymentTerm = standardPaymentTerms.find(
              (p: any) => p.CompanyCode === paymentTermItem.CompanyCode
            );
            if (standardPaymentTerm) {
              paymentTermItem.paymentTermItem = standardPaymentTerm.PaymentTerm;
            }
          }
        );
      }
    }

    this.context.on("AgreementAttachments", (fileList: any) => {
      if (fileList !== "") {
        this.dynamicForm.formGroup
          .get("AgreementAttachments")
          .patchValue(fileList);
        this.dynamicForm.formGroup
          .get("AgreementAttachments")
          .clearValidators();
        this.dynamicForm.formGroup
          .get("AgreementAttachments")
          .updateValueAndValidity({ onlySelf: true, emitEvent: true });
      } else {
        this.dynamicForm.formGroup
          .get("AgreementAttachments")
          .setValidators(Validators.required);
        this.dynamicForm.formGroup
          .get("AgreementAttachments")
          .updateValueAndValidity({ onlySelf: true, emitEvent: true });
      }
    });

    this.context.on("RequestFormAttachments", (fileList: any) => {
      if (fileList !== "") {
        this.dynamicForm.formGroup
          .get("RequestFormAttachments")
          .patchValue(fileList);
        this.dynamicForm.formGroup
          .get("RequestFormAttachments")
          .clearValidators();
        this.dynamicForm.formGroup
          .get("RequestFormAttachments")
          .updateValueAndValidity({ onlySelf: true, emitEvent: true });
      } else {
        this.dynamicForm.formGroup
          .get("RequestFormAttachments")
          .setValidators(Validators.required);
        this.dynamicForm.formGroup
          .get("RequestFormAttachments")
          .updateValueAndValidity({ onlySelf: true, emitEvent: true });
      }
      // this.dynamicForm.formGroup.value.RequestFormAttachments = fileList
      // this.requestForm.AddRequestFormAttachments = fileList
    });
    this.context.on("ApproveAttachments", (fileList: any) => {
      if (fileList !== "") {
        this.dynamicForm.formGroup
          .get("ApproveAttachments")
          .patchValue(fileList);
      }
    });
    // get payment terms list config
    this.multiplePaymentTermsConfig =
      await this.metadataService.getPageConfiguration(
        "buyer-requestform-step2"
      );
    let multiplePaymentTermsSet;
    for (const configItemList of this.multiplePaymentTermsConfig) {
      multiplePaymentTermsSet = configItemList.fieldset.filter(
        (fieldItem: ComponentConfig) => {
          return (
            fieldItem.type === "layout" &&
            JSON.stringify(fieldItem.fieldset).includes("PaymentTermList")
          );
        }
      );
    }
    this.multiplePaymentTermsConfig[0].fieldset = multiplePaymentTermsSet;

    this.loadingService.closeLoading();
  }

  async submit(callbackfn: (validate: boolean, isSuccess: boolean) => void) {
    this.context.changeSubmitState();
    if (!this.validate()) {
      if (callbackfn) {
        callbackfn(false, false);
      }
      return;
    }

    function replacer(key, value) {
      if (key === "AgreementAttachments" && value.length === 0) {
        return undefined;
      }
      if (value === "") {
        return undefined;
      }
      if (key === "PaymentTermId") {
        return undefined;
      }
      return value;
    }

    try {
      const saveUrl = `${environment.gateway}${LoadingUrlMapping.addChangeRequest}`;
      this.loadingService.openLoading(saveUrl);
      const supplier = this.onBoardingService.supplierModel;
      const request = initChangeRequestMapping(supplier.Mapping.supplierCode);
      request.requestType = ChangeRequestType.UPDATE_PAYMENT_TERMS;
      request.requestValue = JSON.stringify(this.dynamicForm.values());
      request.createdOn = moment.utc().toString();
      request.createdBy = this.authService.passport.profile.name;
      request.comments = this.comment;
      request.supplierModel = supplier;
      const audit = initAuditChangeRequestDetail();
      const oldPaymentTermsList: any = this.previousPaymentTermsList;
      const newPaymentTermsList: any =
        this.dynamicForm.values().PaymentTermList;
      audit.actionTaken = "";
      audit.comments = this.comment;
      audit.createdBy = this.authService.passport.profile.name;
      audit.createdOn = moment.utc().toString();
      const oldPaymentTermsListString = JSON.stringify(
        JSON.stringify(oldPaymentTermsList, replacer)
          .replace(/\{|\}|\]|\[/g, "")
          .split(",")
          .sort()
      );
      const newPaymentTermsListString = JSON.stringify(
        JSON.stringify(newPaymentTermsList, replacer)
          .replace(/\{|\}|\]|\[/g, "")
          .split(",")
          .sort()
      );
      if (oldPaymentTermsListString === newPaymentTermsListString) {
        this.loadingService.closeLoading();
        await this.dialogService.dialog(
          `Please be aware that you didn't make any changes in payment terms fields.`,
          ButtonCommands.Close,
          DialogType.warning,
          "Please Note"
        );
        return;
      }
      // compare payment terms value
      audit.addPaymentTermsList = [];
      oldPaymentTermsList.forEach((oldItem: PaymentTermsItem) => {
        newPaymentTermsList.forEach((newItem: PaymentTermsItem) => {
          if (
            oldItem.PaymentTermId == newItem.PaymentTermId &&
            JSON.stringify(oldItem) !== JSON.stringify(newItem)
          ) {
            const diffPaymentTermsItem: PaymentTermsItem =
              initMultiplePaymentTermsCRItem();
            this.comparePaymentTermItem(
              PaymentTermsItemKey.NonStandardType,
              diffPaymentTermsItem,
              oldItem.HasNonStandardPaymentTerms,
              newItem.HasNonStandardPaymentTerms
            );
            this.comparePaymentTermItem(
              PaymentTermsItemKey.TermPayment,
              diffPaymentTermsItem,
              oldItem.TermPayment,
              newItem.TermPayment
            );
            this.comparePaymentTermItem(
              PaymentTermsItemKey.AgreementAttachments,
              diffPaymentTermsItem,
              oldItem.AgreementAttachments,
              newItem.AgreementAttachments
            );
            this.comparePaymentTermItem(
              PaymentTermsItemKey.ApproveAttachments,
              diffPaymentTermsItem,
              oldItem.ApproveAttachments,
              newItem.ApproveAttachments
            );
            this.comparePaymentTermItem(
              PaymentTermsItemKey.ProcurementApproval,
              diffPaymentTermsItem,
              oldItem.ProcurementApproval,
              newItem.ProcurementApproval
            );
            if (
              diffPaymentTermsItem.HasNonStandardPaymentTerms !== undefined ||
              diffPaymentTermsItem.TermPayment !== undefined ||
              diffPaymentTermsItem.AgreementAttachments !== undefined ||
              diffPaymentTermsItem.ApproveAttachments !== undefined ||
              diffPaymentTermsItem.ProcurementApproval !== undefined
            ) {
              diffPaymentTermsItem.Country = oldItem.Country;
              diffPaymentTermsItem.CompanyCode = oldItem.CompanyCode;
              diffPaymentTermsItem.TimeLimit = oldItem.TimeLimit;
              audit.addPaymentTermsList.push(diffPaymentTermsItem);
            }
          }
        });
      });
      const tempPaymentTermList = [];
      audit.addPaymentTermsList.forEach((paymentTermDTO, i) => {
        const countryPaymentTerm = {
          country: paymentTermDTO.Country,
          companyCodepaymentTermList: [],
        };
        const paymentTermVO = {
          paymentTerm: {
            TimeLimit: paymentTermDTO.TimeLimit,
            TermPayment: paymentTermDTO.TermPayment
              ? audit.addPaymentTermsList.filter(
                  (d) => d.CompanyCode == paymentTermDTO.CompanyCode
                ).length == 0
                ? undefined
                : audit.addPaymentTermsList.filter(
                    (d) => d.CompanyCode == paymentTermDTO.CompanyCode
                  )[0].TermPayment
              : undefined,
            HasNonStandardPaymentTerms: audit.addPaymentTermsList
              ? audit.addPaymentTermsList.filter(
                  (d) => d.CompanyCode == paymentTermDTO.CompanyCode
                ).length == 0
                ? undefined
                : audit.addPaymentTermsList.filter(
                    (d) => d.CompanyCode == paymentTermDTO.CompanyCode
                  )[0].HasNonStandardPaymentTerms
              : undefined,
            AgreementAttachments: audit.addPaymentTermsList
              ? audit.addPaymentTermsList.filter(
                  (d) => d.CompanyCode == paymentTermDTO.CompanyCode
                ).length == 0
                ? undefined
                : audit.addPaymentTermsList.filter(
                    (d) => d.CompanyCode == paymentTermDTO.CompanyCode
                  )[0].AgreementAttachments
              : undefined,
            ApproveAttachments: audit.addPaymentTermsList
              ? audit.addPaymentTermsList.filter(
                  (d) => d.CompanyCode == paymentTermDTO.CompanyCode
                ).length == 0
                ? undefined
                : audit.addPaymentTermsList.filter(
                    (d) => d.CompanyCode == paymentTermDTO.CompanyCode
                  )[0].ApproveAttachments
              : undefined,
            ProcurementApproval: audit.addPaymentTermsList
              ? audit.addPaymentTermsList.filter(
                  (d) => d.CompanyCode == paymentTermDTO.CompanyCode
                ).length == 0
                ? undefined
                : audit.addPaymentTermsList.filter(
                    (d) => d.CompanyCode == paymentTermDTO.CompanyCode
                  )[0].ProcurementApproval
              : undefined,
          },
          CompanyCode: paymentTermDTO.CompanyCode,
        };
        countryPaymentTerm.companyCodepaymentTermList.push(paymentTermVO);
        tempPaymentTermList.push(countryPaymentTerm);
      });
      const record = {};
      const diffMultiplePaymentTermsList = [];
      tempPaymentTermList.forEach((countryPaymenTerm) => {
        if (!record[countryPaymenTerm.country]) {
          diffMultiplePaymentTermsList.push(countryPaymenTerm);
          record[countryPaymenTerm.country] = true;
        } else {
          diffMultiplePaymentTermsList.forEach((paymentTermVO) => {
            if (paymentTermVO.country === countryPaymenTerm.country) {
              paymentTermVO.companyCodepaymentTermList =
                paymentTermVO.companyCodepaymentTermList.concat(
                  countryPaymenTerm.companyCodepaymentTermList
                );
            }
          });
        }
      });
      diffMultiplePaymentTermsList.forEach((countryItem) => {
        countryItem["isShowCountry"] = true;
        countryItem.companyCodepaymentTermList.forEach((companyItem) => {
          companyItem["isShowCompany"] = true;
        });
      });
      audit.addPaymentTermsList = diffMultiplePaymentTermsList;
      request.audit = [audit];
      const result = await this.buyerChangeRequest.submitRequestor(request);
      // console.log("result", result);
      if (callbackfn) {
        callbackfn(true, result);
      }
    } catch (e) {
      // console.log(e);
      if (callbackfn) {
        callbackfn(true, false);
      }
    }
  }

  addAttachmentInfo(fileList) {
    // this.requestForm.RequestFormInfo.AgreementAttachments = fileList
  }

  comparePaymentTermItem(
    itemType: string,
    item: PaymentTermsItem,
    oldValue: any,
    newValue: any
  ) {
    switch (itemType) {
      case PaymentTermsItemKey.NonStandardType:
      case PaymentTermsItemKey.TermPayment:
        if (
          !(oldValue === undefined && newValue === "") &&
          oldValue !== newValue
        ) {
          item[itemType].oldValue = oldValue;
          item[itemType].newValue = newValue;
        } else {
          delete item[itemType];
        }
        break;
      case PaymentTermsItemKey.AgreementAttachments:
      case PaymentTermsItemKey.ApproveAttachments:
      case PaymentTermsItemKey.ProcurementApproval:
        if (
          !(oldValue === undefined && newValue === "") &&
          this.compareArray(oldValue, newValue)
        ) {
          if (oldValue) {
            oldValue.forEach((oldAttachmentItem) => {
              if (
                !JSON.stringify(newValue).includes(
                  JSON.stringify(oldAttachmentItem)
                )
              ) {
                item[itemType].oldValue.push(oldAttachmentItem);
              }
            });
          }
          item[itemType].newValue = newValue;
        } else {
          delete item[itemType];
        }
        break;
      default:
        break;
    }
  }

  validate(): boolean {
    return this.dynamicForm.valide();
  }

  get isAllFileUploaded() {
    const agreementAttachmentsList = [];
    // if (this.requestForm.RequestFormInfo.AgreementAttachments && this.requestForm.RequestFormInfo.AgreementAttachments.length > 0) {
    //   this.requestForm.RequestFormInfo.AgreementAttachments.forEach(item => {
    //     if (item.isUploading) {
    //       agreementAttachmentsList.push(item)
    //     }
    //   })
    // }
    return !(agreementAttachmentsList.length === 0);
  }
  estimateChooseOver(e, estimateSpendTooltip) {
    const TooltipEle = estimateSpendTooltip;
    if (estimateSpendTooltip) {
      if (
        e.clientX < TooltipEle.nativeElement.getBoundingClientRect().left ||
        e.clientX >
          TooltipEle.nativeElement.getBoundingClientRect().left +
            TooltipEle.nativeElement.offsetWidth -
            5 ||
        e.clientY < TooltipEle.nativeElement.getBoundingClientRect().top ||
        e.clientY >
          TooltipEle.nativeElement.getBoundingClientRect().top +
            TooltipEle.nativeElement.offsetHeight -
            5
      ) {
        this.showTooltip = false;
      }
    } else {
      this.showTooltip = false;
    }
  }

  compareArray(oldValue, newValue) {
    let flag = true
    if (Array.isArray(oldValue) && Array.isArray(newValue)) {
      if (oldValue.length === newValue.length) {
        oldValue.forEach(old => {
          if (JSON.stringify(newValue).includes(
            JSON.stringify(old.key))) {
              flag = false
            }
        })
      }
    } else {
      flag = oldValue !== newValue
    }
    return flag
  }
}
