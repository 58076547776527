import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  AfterViewInit,
} from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { CommonService } from "../../../../../services/common.service";
import { SupplierService } from "../../../../../services/supplier.service";
import {
  PhoneInfo,
  GeneralStatus,
  SupplierInfoType,
} from "../../../../../interfaces/supplier";
import {
  SupplierProfile,
  SupplierGeneral,
} from "../../../../../interfaces/supplierProfile";
import { ProfileService } from "../../../services/profile.service";
import {
  AP_ReviewStatus,
  StatusType,
  SupplierStatus,
  LoadingUrlMapping,
} from "src/app/interfaces/mapping";
import { cloneDeep, trim } from "src/app/utils";
import {
  ButtonCommand,
  DialogService,
  ButtonCommands,
  DialogType,
} from "src/app/services/dialog.service";
import { BuyerService } from "src/app/services/buyer.service";
import { AuthService } from "src/app/services/auth/auth.service";
import VMController from "src/app/interfaces/vm";
import { CanComponentDeactivate } from "src/app/can-deactivate.guard";
import { SupplierPendingStatus } from "src/app/interfaces/mapping";
import { SpendCategoryGroup } from "src/app/interfaces/mapping";
import { RiskAssessmentStatus } from "src/app/interfaces/buyer";
import { OnBoardingService } from '../../../../buyer/services/onboarding.service'
import { environment } from "src/environments/environment";
import { LoadingService } from "src/app/services/loading.service";
import {
  SetupProcess,
  ProcessNames,
  AllTaskKey,
} from "src/app/interfaces/workflow";
import { supplierGeographical } from "src/app/shared/shared";
import { DynamicContext } from "src/app/dynamic-components/interfaces/DynamicContext";
import { DynamicFormComponent } from "src/app/dynamic-components/components/dynamic-form/dynamic-form.component";
import { MetadataService } from "src/app/services/metadata.service";
import { ComponentConfig } from "src/app/dynamic-components/ComponentConfig";
import { UntypedFormGroup, Validators } from "@angular/forms";
import { UploaderComponent } from "src/app/shared/custom/components/uploader/uploader.component";
import { SupplierCommonService } from "../../../services/supplierCommon.service";
import { LanguageService } from "../../../../../dynamic-components/utils/language.service";
import { showGlobalPrepopulateData } from "../../../../../../config/config";
import { MatDialog } from '@angular/material/dialog';
import { GlobalPopupComponent } from "src/app/global-popup/global-popup.component";

const emailPattern =
  /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,})$/;

@Component({
  selector: "app-profile-general",
  templateUrl: "./general.component.html",
  styleUrls: ["./general.component.sass"],
})
export class ProfileGeneralComponent implements OnInit, CanComponentDeactivate {
  private generalStatus: GeneralStatus = {};
  public disabled = "disabled";
  private dictCountry: any;
  private dictGeographiesServed: any;
  // private apReviewStatus: string    // old status verify
  private dictPhone: any;
  // private supplierStatus: any    // old status verify
  dictSoftwareOrWBTool: any;
  private isNew = true;
  AP_ReviewStatus = AP_ReviewStatus;
  SupplierStatus = SupplierStatus;
  globalAnwsers = [];
  globalNameElement:any;
  dialogRef: any;
  getglobalVal: any;
  private isValidated: boolean;
  private supplierInfoType = SupplierInfoType;
  // Spend Category Group
  private spendCategoryGroup = SpendCategoryGroup;
  private riskAssessmentStatus: RiskAssessmentStatus = {};
  showAlertPanel = false;
  isDisabledSaveBtn = false;
  isValidSecondaryEmail = true;
  uploadingFiles?: Array<any> = [];
  context: DynamicContext;

  isResubmitDisabled = true;
  @ViewChild("dynamicForm") dynamicForm: DynamicFormComponent;

  @ViewChild("uploader")
  uploaderComponent: UploaderComponent;

  getConfigsInfo: ComponentConfig[] = [];
  originalConfig: ComponentConfig[] = [];
  formGroup: UntypedFormGroup = new UntypedFormGroup({});
  vm: VMController<SupplierGeneral> = new VMController();

  get mode(): string {
    return this.profileService.mode;
  }

  get generalInfo(): SupplierGeneral {
    return this.profileService.supplierModel.SupplierProfile.SupplierGeneral;
  }

  set generalInfo(val) {
    this.profileService.supplierModel.SupplierProfile.SupplierGeneral = val
  }

  get spendCategoryGroupValue(): string {
    if (this.context.getValue("parentModel").RequestForm.RequestorDetailsInfo.IndependentContractor == "Y") {
      SpendCategoryGroup['5002'] = false
      SpendCategoryGroup['5021'] = false
    } else {
      SpendCategoryGroup['5002'] = true
      SpendCategoryGroup['5021'] = true
    }
    return this.profileService.supplierModel.RequestForm.RequestorDetailsInfo
      .SpendCategoryGroup;
  }

  get isView() {
    if (!this.profileService.isView) {
      this.getConfigsInfo = this.originalConfig;
    }
    this.context.mode = this.mode;
    return this.profileService.isView;
  }

  constructor(
    private router: Router,
    private commonS: CommonService,
    private supplierService: SupplierService,
    private buyerService: BuyerService,
    private route: ActivatedRoute,
    private profileService: ProfileService,
    private dialogService: DialogService,
    private auth: AuthService,
    private onBoardingService: OnBoardingService,
    private loadingService: LoadingService,
    private metadataService: MetadataService,
    private supplierCommonService: SupplierCommonService,
    private languageService: LanguageService,
    private dialog: MatDialog
  ) {
    this.profileService.stepChangeEvent.emit(1);
  }

  async ngOnInit() {
    this.loadingService.showLoading();
    this.profileService.current_step = 1;
    // load current login user's task
    // await this.profileService.loadUserTasks()
    // await this.onBoardingService.loadSupplierRequestForm()
    // await this.supplierCommonService.SetContextValue(this.context)
    this.context = new DynamicContext();
    this.context.mode = this.mode;
    if (!this.generalInfo.Phone) {
      this.generalInfo.Phone = "";

      // Commenting the below code as per the bug: 368406
      // Primary contact phone is not visible when click on view details from supplier side
      // this.generalInfo.PrimaryContactPhone = {
      //   AreaCode: "",
      //   CountryCode: "",
      // };
    }
    const [getConfigsInformation, tasks, contexts] = await Promise.all([
      this.metadataService.getPageConfiguration("seller-profile-step1"),
      this.profileService.loadUserTasks(),
      this.supplierCommonService.SetContextValue(this.context),
    ]);
    // this.getConfigsInfo = await this.metadataService.getPageConfiguration('seller-profile-step1')
    if (this.generalInfo.SoftwareOrWBTool) {
      this.changeValueStatus();
    }

    this.profileService.current_step = 1;

    // this.onBoardingService.supplierCode = this.profileService.supplierCode

    this.loadCheckQuestion();

    this.commonS.ExitEvent.subscribe((e) => {
      this.vm.setOriginal(this.dynamicForm.originalValues());
      this.vm.setCurrent(this.dynamicForm.values());
      if (this.vm.isDirty()) {
        e.preventDefault();
        // Chrome requires returnValue to be set
        e.returnValue = "";
      }
    });
    if (!this.isView) {
      this.dynamicForm.subscribeToFormChange(() => {
        setTimeout(() => {
          this.isResubmitDisabled =
            JSON.stringify(this.dynamicForm.originalValues()) ===
            JSON.stringify(this.dynamicForm.values());
        });
      });
    }
    // this.apReviewStatus = this.profileService.supplierModel.Mapping.AP_reviewStatus
    // get Status from db
    // this.supplierStatus = this.profileService.supplierModel.Mapping.status
    this.context.set(
      "isThailand",
      this.profileService.supplierModel.RequestForm.RequestorDetailsInfo.SupplierCountry.includes(
        "TH"
      )
    );
    this.context.set(
      "MPhoneIsNull",
      this.profileService.supplierModel.SupplierProfile.SupplierGeneral.MPhone
    );
    this.getConfigsInfo = getConfigsInformation;
    this.originalConfig = cloneDeep(this.getConfigsInfo);
    if (!this.isView) {
      this.dynamicForm.subscribeToModelChange("AddGeneralAttachments", (v) => {
        if (v === "") {
          this.dynamicForm.formGroup
            .get("AddGeneralAttachments")
            .patchValue([]);
        }
      });
    }
    this.loadingService.hideLoading();
    // console.log('MODE', this.onBoardingService.mode)
    if ((this.onBoardingService.mode == 'edit' || this.onBoardingService.mode == 'create') && showGlobalPrepopulateData ){
      this.formAutofill()
    }
  }

  async formAutofill() {
    const data = this.profileService.supplierModel;
    console.log(data)
    let payload = {
      supplierCode: this.profileService.supplierCode, 
      SupplierCompanyName: this.profileService.supplierModel.SupplierProfile.SupplierGeneral.CompanyName,
      supplierTsmId: this.profileService.supplierModel.Mapping.TsmId,
      countryServed: this.profileService.supplierModel.RequestForm.RequestorDetailsInfo.SupplierCountry,
      RoleCode: this.auth.passport.supplierMember.RoleCode
    }
    // console.log('Global payload', payload);
   if(payload.supplierCode && payload.supplierTsmId){
     let getGlobalQAs;
     if (localStorage.getItem("globalqa") == null) {
       const getGlobalQAs = await this.supplierService.getGlobalFieldData(payload);
       localStorage.setItem("globalqa", JSON.stringify(getGlobalQAs))
     }
     else {
       getGlobalQAs = JSON.parse(localStorage.getItem("globalqa"));
     }
    // console.log('formAutofill this.getConfigsInfo Data1', this.getConfigsInfo);
    // console.log('formAutofill getAllAnswers', getGlobalQAs);
     if (this.getConfigsInfo && this.getConfigsInfo.length && getGlobalQAs) {
      this.getConfigsInfo.forEach(item => {
        item['fieldset'].forEach(field => {
          // console.log('field', field)
          // console.log('Global', field['global'])
          if (field['global']) {
            let filterAnwsers = getGlobalQAs.filter((question) => question['QuestionName'] == field['name'])
            // console.log(" filterAnwsers Before Try :", filterAnwsers);
            if (filterAnwsers && filterAnwsers.length) {
                filterAnwsers = filterAnwsers[0]
                let a;
                if ( filterAnwsers.QuestionName == 'UploadCompanysAddress' || filterAnwsers.QuestionName == 'AddGeneralAttachments'){
                  try {
                    a = JSON.parse(filterAnwsers.Answer);
                    filterAnwsers.Answer = a
                    // console.log("filterAnwsers After Try :", a);
                    this.globalAnwsers.push(filterAnwsers)
                  } catch (error) {
                    // console.log("General Componenent:", error);
                    this.globalAnwsers.push(filterAnwsers)
                  }
                } else{
                  this.globalAnwsers.push(filterAnwsers)
                }
            }
          }
        })
      })
    }
    // console.log('formAutofill globalAnwsers', this.globalAnwsers)
    let generalInfoData = Object.assign({}, this.generalInfo)
    // console.log('formAutofill generalInfoData', generalInfoData);  
    this.globalAnwsers.forEach((anwserItem) => {
      if (anwserItem != null && anwserItem != undefined) {
        // console.log('formAutofill this.generalInfo', this.generalInfo);  
        if((this.generalInfo[anwserItem['QuestionName']] == "") || (this.generalInfo[anwserItem['QuestionName']] == null) || (this.generalInfo[anwserItem['QuestionName']] == undefined)){
          // console.log('formAutofill Prepopulate Values', anwserItem['Answer']);    
          generalInfoData[anwserItem['QuestionName']] = anwserItem['Answer']
        }
      }
    })
    this.generalInfo = generalInfoData
    // console.log('formAutofill this.generalInfo', this.generalInfo);  
    setTimeout(() => {
      console.log('formAutofill Calling updateFormValues');  
      this.dynamicForm.updateFormValues()
    });
      }
      setTimeout(() => {   
        if(!localStorage.getItem('generalsaved') && localStorage.getItem('generalsaved') !== 'yes') {
          this.SaveFirsttime();
        }           
        console.log("settimeout");
        const elements: HTMLInputElement[] = [];
      
        for (let gobalVal of this.globalAnwsers) {
          let formControlType: string;
      
          if (gobalVal['AnswerInputField'] === 'dropdown') {
            formControlType = 'select';
          } else if (gobalVal['AnswerInputField'] === 'input') {
            formControlType = 'input';
          } else if (gobalVal['AnswerInputField'] === 'radio') {
            formControlType = 'radio';
          }
      
          const actualElement = document.querySelector<HTMLInputElement>(`[id^="${gobalVal['QuestionName']}${formControlType}"]`);
      
          if (actualElement) {
            elements.push(actualElement);
          }
        }
      
        for (const element of elements) {
          element.addEventListener("change", (e) => {
            // console.log("Field change??? ===>", e);
            const targetElement = e.currentTarget as HTMLElement;
            if (targetElement) {
              targetElement.style.border = 'solid 1px #414187';
            }
          });
        }
      });
}

highlightInput() {
  const selectedElements = this.globalAnwsers.filter(globalval => this.dynamicForm.formGroup.controls[globalval.QuestionName].value !== globalval.Answer);
  
  if(selectedElements.length > 0) {
    this.globalAnwsers.forEach (eachItem => {
      const actualElement = document.querySelector<HTMLInputElement>(`[id^="${eachItem['QuestionName']}${this.formControlType(eachItem)}"]`);
      if(actualElement) {
        actualElement.style.border = this.formControlType(eachItem) === 'label' ? 'none' : 'solid 1px #414187';
        this.dynamicForm.formGroup.controls[eachItem.QuestionName].setValue(eachItem.Answer);
      }
    });
    setTimeout(() => {
    selectedElements.forEach(element => {
      const actualElement = document.querySelector<HTMLInputElement>(`[id^="${element['QuestionName']}${this.formControlType(element)}"]`);
      if (actualElement) {
        actualElement.style.border = '2px solid orange';
      }
    });
  },200)
  }
}

formControlType(globalValue) {
  const inputField = globalValue['AnswerInputField'];
  switch (inputField) {
    case 'dropdown':
      return 'select';
    case 'radio':
      return 'label';
    case 'input':
    case 'textbox':
      return 'input';
    default:
      return '';
  }
}


  openGlobalPopup() {
    this.dialogRef = this.dialog.open(GlobalPopupComponent, {
      width: '450px',
    });
  
    this.dialogRef.afterClosed().subscribe(result => {
      for (this.getglobalVal of this.globalAnwsers) {
        const supplierControl = this.dynamicForm.formGroup.controls[this.getglobalVal.QuestionName];
        if (supplierControl) {
          if (this.dynamicForm.formGroup.controls[this.getglobalVal.QuestionName].value !== this.getglobalVal.Answer) {
            if (result === 'onConfirmClick') {
              console.log("Save supplier details on confirm & navigate to next page");
              this.saveDraft().then((data: boolean) => {
                if (data) {
                  this.vm.saved();
                  this.router.navigate([
                    `supplier/profile/${this.profileService.supplierCode}/step2`,
                  ]);
                 }
              });
              
            } else if (result === 'onCancelClick') {
              this.highlightInput();
              supplierControl.setValue(this.getglobalVal.Answer);
            }
          }
        }
      }
    });
  }
  openGlobalPopupForResubmit() {
    this.dialogRef = this.dialog.open(GlobalPopupComponent, {
      width: '450px',
    });
  
    this.dialogRef.afterClosed().subscribe(result => {
      for (this.getglobalVal of this.globalAnwsers) {
        const supplierControl = this.dynamicForm.formGroup.controls[this.getglobalVal.QuestionName];
        if (supplierControl) {
          if (this.dynamicForm.formGroup.controls[this.getglobalVal.QuestionName].value !== this.getglobalVal.Answer) {
            if (result === 'onConfirmClick') {
              this.changeRequest().then((data: boolean) => {
                if (data) {
                  this.vm.saved();
                  this.dialogService
                    .dialog(
                      this.languageService.getValue(
                        "static.supplier.components.general.change_request"
                      ),
                      ButtonCommands.Ok,
                      DialogType.success,
                      this.languageService.getValue(
                        "static.supplier.components.general.please_note"
                      )
                    )
                    .then((result: boolean) => {
                      this.router.navigate([`supplier/landing`]);
                    });
                }
              });
            } else if (result === 'onCancelClick') {
              this.highlightInput();
              supplierControl.setValue(this.getglobalVal.Answer);
            }
          }
        }
      }
    });
  }
  checkAndOpenGlobalPopup() {
  const globalDirty = this.globalAnwsers.some(globalAnswerVal => {
    const control = this.dynamicForm.formGroup.controls[globalAnswerVal.QuestionName];
    if (control && this.dynamicForm.formGroup.controls[globalAnswerVal.QuestionName].value !== globalAnswerVal.Answer) {
      if (globalAnswerVal.QuestionName === 'AddGeneralAttachments' && globalAnswerVal.Answer == "" && control.value.length == 0) {
        return false
      }
      return true
    } else {
      return false
    }
  });
  return globalDirty;
   }

  get showResubmitBtn() {
    if (this.dynamicForm) {
      if (
        !this.isView &&
        !this.dynamicForm.disabled &&
        this.dynamicForm.formGroup
      ) {
        this.isResubmitDisabled =
          JSON.stringify(this.dynamicForm.originalValues()) ===
          JSON.stringify(this.dynamicForm.values());
      }
    }
    return this.profileService.checkResubmit();

    // workflow comments: remove supplier change request
    // return (this.apReviewStatus === AP_ReviewStatus.SST_reject || this.supplierStatus === 'Onboarded') && !this.isView && !this.profileService.checkIsMigrateSubmit()
  }

  loadCheckQuestion() {
    if (this.spendCategoryGroup[this.spendCategoryGroupValue]) {
      this.riskAssessmentStatus.IsInformationSecurity = true;
      this.context.set("IsInformationSecurity", true);
    } else {
      this.riskAssessmentStatus.IsInformationSecurity = false;
      this.context.set("IsInformationSecurity", false);
    }
  }

  async canDeactivate() {
    if (this.isView) {
      return true;
    }
    this.vm.setOriginal(this.dynamicForm.originalValues());
    this.vm.setCurrent(this.dynamicForm.values());
    if (this.vm.isDirty()) {
      const result = await this.dialogService.saveConfirm();
      if (result === "save") {
        if (!(await this.saveDraft())) {
          return false;
        }
      }
      if (result === "cancel") {
        return false;
      }
    }

    return true;
  }

  change() {
    this.disabled = "";
  }

  trimChildValue(value) {
    return value.trim();
  }

  btnNext() {
    this.router.navigate([
      `supplier/profile/${this.profileService.supplierCode}/step2`,
    ]);
  }

  async btnSave() {
    if(this.checkAndOpenGlobalPopup()){
      this.openGlobalPopup();
    }
    else{
    this.saveDraft().then((data: boolean) => {
      if (data) {
        this.vm.saved();
        this.router.navigate([
          `supplier/profile/${this.profileService.supplierCode}/step2`,
        ]);
       }
    });
  }
  }

  // async save(): Promise<boolean> {
  //   this.profileService.supplierModel.SupplierProfile.SupplierGeneral = trim(this.generalInfo)
  //   this.generalInfo.PrimaryContactPhone.AreaCode = this.trimChildValue(this.generalInfo.PrimaryContactPhone.AreaCode)
  //   this.generalInfo.Phone = this.trimChildValue(this.generalInfo.Phone)
  //   if (this.generalInfo.MPhone) {
  //     this.generalInfo.MPhone = this.trimChildValue(this.generalInfo.MPhone)
  //   }

  //   if (!this.validate()) {
  //     return false
  //   }
  //   const saveUrl = `${environment.gateway}${LoadingUrlMapping.saveProfileDraft}`
  //   this.loadingService.openLoading(saveUrl)
  //   if (this.profileService.profile_step === 1) {

  //     // workflow comments: remove details status
  //     // this.profileService.supplierModel.Mapping.detailStatus = SupplierPendingStatus.inProfile_draft
  //   }

  //   // start supplier change request process
  //   if ((this.profileService.supplierModel.Mapping.supplierSetup
  //     && this.profileService.supplierModel.Mapping.supplierSetup === SetupProcessStatus.supplierSetupComplete) // supplier setup process complete
  //     || (this.profileService.supplierModel.Mapping.supplierMigrate
  //       && this.profileService.supplierModel.Mapping.supplierMigrate === MigrateProcessStatus.supplierMigrateComplete) // or supplier migrate process complete
  //   ) {
  //     // await this.profileService.startProcess(ProcessNames.supplierChangeRequest)
  //   }

  //   this.profileService.supplierModel.RequestForm = await this.loadBuyer()
  //   this.profileService.supplierModel.RequestForm.RequestorDetailsInfo.SupplierCompanyName = this.profileService.supplierModel.SupplierProfile.SupplierGeneral.CompanyName
  //   this.profileService.supplierModel.RequestForm.RequestorDetailsInfo.SupplierContactFirstName
  //     = this.profileService.supplierModel.SupplierProfile.SupplierGeneral.PrimaryContactFirstNm
  //   this.profileService.supplierModel.RequestForm.RequestorDetailsInfo.SupplierContactLastName
  //     = this.profileService.supplierModel.SupplierProfile.SupplierGeneral.PrimaryContactLastNm
  //   this.generalInfo.PrimaryContactNm = this.profileService.supplierModel.SupplierProfile.SupplierGeneral.PrimaryContactFirstNm
  //     .concat(' ', this.profileService.supplierModel.SupplierProfile.SupplierGeneral.PrimaryContactLastNm)
  //   this.profileService.supplierModel.SupplierProfile.SupplierGeneral.PrimaryContactNm = this.generalInfo.PrimaryContactNm
  //   this.profileService.supplierModel.RequestForm.RequestorDetailsInfo.SupplierContactPoint = this.profileService.supplierModel.SupplierProfile.SupplierGeneral.PrimaryContactNm

  //   this.profileService.supplierModel.Mapping.profile.createdOn = new Date().toUTCString()
  //   this.profileService.supplierModel.Mapping.requestor.updatedBy = this.auth.passport.profile.email
  //   this.profileService.supplierModel.Mapping.searchTerm.supplierName = this.profileService.supplierModel.SupplierProfile.SupplierGeneral.CompanyName
  //   const saveList = [await this.buyerService.saveRequestForm(this.profileService.supplierModel), await this.profileService.saveSupplierProfile()]
  //   Promise.all(saveList)
  //   return true
  // }

  // resubmit request form
  async resubmitForm(): Promise<boolean> {
    this.context.changeSubmitState();
    this.dynamicForm.removeFormTouch();
    if (!this.validate()) {
      return false;
    }
    await this.updateSupplierMapping();
    // get executable task GUID: taskInstId
    this.profileService.getResubmitTask();
    if (!this.profileService.supplierModel.Mapping.executeTask) {
      return false;
    }
    const saveUrl = `${environment.gateway}${LoadingUrlMapping.resubmitSupplierProfile}`;
    this.loadingService.openLoading(saveUrl);
    const saveList = [
      await this.buyerService.saveRequestFormDraft(
        this.profileService.supplierModel
      ),
      await this.profileService.resubmitForm(),
    ];
    Promise.all(saveList);
    return true;
  }

  // resubmit request form
  async changeRequest(): Promise<boolean> {
    this.context.changeSubmitState();
    this.dynamicForm.removeFormTouch();
    if (!this.validate()) {
      return false;
    }
    await this.updateSupplierMapping();
    const saveUrl = `${environment.gateway}${LoadingUrlMapping.requestChangeSupplierProfile}`;
    this.loadingService.openLoading(saveUrl);
    const saveList = [
      await this.buyerService.saveRequestFormDraft(
        this.profileService.supplierModel
      ),
      await this.profileService.changeRequestForm(),
    ];
    Promise.all(saveList);
    return true;
  }

  // save draft request form
  async saveDraft(): Promise<boolean> {
    this.context.changeSubmitState();
    this.dynamicForm.removeFormTouch();
    if (!this.validate()) {
      return false;
    }
    await this.updateSupplierMapping();
    const saveUrl = `${environment.gateway}${LoadingUrlMapping.saveProfileDraft}`;
    this.loadingService.openLoading(saveUrl);
    const saveList = [
      await this.buyerService.saveRequestFormDraft(
        this.profileService.supplierModel
      ),
      await this.profileService.saveDraft(),
    ];
    Promise.all(saveList);
    return true;
  }
  async SaveFirsttime(): Promise<boolean> {
    this.profileService.supplierModel.SupplierProfile.SupplierGeneral = trim(
      this.dynamicForm.values()
    );
      const saveUrl = `${environment.gateway}${LoadingUrlMapping.saveProfileDraft}`;
      this.loadingService.openLoading(saveUrl);
      const saveList = [
        await this.buyerService.saveRequestFormDraft(
          this.profileService.supplierModel
        ),
        await this.profileService.saveDraft(),
      ];
      Promise.all(saveList);
      localStorage.setItem('generalsaved', 'yes');
      return true;
  }
  // update supplier mapping info
  private async updateSupplierMapping() {
    this.profileService.supplierModel.SupplierProfile.SupplierGeneral = trim(
      this.dynamicForm.values()
    );
    this.generalInfo.PrimaryContactPhone.AreaCode = this.trimChildValue(
      this.generalInfo.PrimaryContactPhone.AreaCode
    );
    if (this.generalInfo.Phone) {
    this.generalInfo.Phone = this.trimChildValue(this.generalInfo.Phone);
    }
    if (this.generalInfo.MPhone) {
      this.generalInfo.MPhone = this.trimChildValue(this.generalInfo.MPhone);
    }
    if (this.profileService.profile_step === 1) {
      this.profileService.supplierModel.Mapping.profile.createdOn =
        new Date().toUTCString();
    }
    this.profileService.supplierModel.RequestForm = await this.loadBuyer();
    this.profileService.supplierModel.RequestForm.RequestorDetailsInfo.SupplierCompanyName =
      this.profileService.supplierModel.SupplierProfile.SupplierGeneral.CompanyName;
    this.profileService.supplierModel.RequestForm.RequestorDetailsInfo.SupplierContactFirstName =
      this.profileService.supplierModel.SupplierProfile.SupplierGeneral.PrimaryContactFirstNm;
    this.profileService.supplierModel.RequestForm.RequestorDetailsInfo.SupplierContactLastName =
      this.profileService.supplierModel.SupplierProfile.SupplierGeneral.PrimaryContactLastNm;
    this.profileService.supplierModel.SupplierProfile.SupplierGeneral.PrimaryContactNm =
      this.profileService.supplierModel.SupplierProfile.SupplierGeneral.PrimaryContactFirstNm.concat(
        " ",
        this.profileService.supplierModel.SupplierProfile.SupplierGeneral
          .PrimaryContactLastNm
      );
    this.profileService.supplierModel.SupplierProfile.SupplierGeneral.PrimaryContactNm =
      this.generalInfo.PrimaryContactNm;
    this.profileService.supplierModel.RequestForm.RequestorDetailsInfo.SupplierContactPoint =
      this.profileService.supplierModel.SupplierProfile.SupplierGeneral.PrimaryContactNm;

    this.profileService.supplierModel.Mapping.requestor.updatedBy =
      this.auth.passport.profile.email;
    this.profileService.supplierModel.Mapping.searchTerm.supplierName =
      this.profileService.supplierModel.SupplierProfile.SupplierGeneral.CompanyName;
  }

  async resubmit() {
    this.context.changeSubmitState();
    this.dynamicForm.removeFormTouch();
    if (!this.validate()) {
      return;
    }
    // resubmit
    if(this.checkAndOpenGlobalPopup()){
      this.openGlobalPopupForResubmit();
    }

    if (
      this.profileService.getUserTaskByTaskKey(AllTaskKey.SST_rejectToSupplier)
    ) {
      this.resubmitForm().then((data: boolean) => {
        if (data) {
          this.vm.saved();
          this.dialogService
            .dialog(
              this.languageService.getValue(
                "static.supplier.components.general.change_request"
              ),
              ButtonCommands.Ok,
              DialogType.success,
              this.languageService.getValue(
                "static.supplier.components.general.please_note"
              )
            )
            .then((result: boolean) => {
              this.router.navigate([`supplier/landing`]);
            });
        }
      });
    } else {
      this.changeRequest().then((data: boolean) => {
        if (data) {
          this.vm.saved();
          this.dialogService
            .dialog(
              this.languageService.getValue(
                "static.supplier.components.general.change_request"
              ),
              ButtonCommands.Ok,
              DialogType.success,
              this.languageService.getValue(
                "static.supplier.components.general.please_note"
              )
            )
            .then((result: boolean) => {
              this.router.navigate([`supplier/landing`]);
            });
        }
      });
    }

}

  validate() {
    return this.dynamicForm.valide();
  }

  async loadBuyer() {
    const data: any = await this.buyerService.queryRequestForm(
      this.profileService.supplierCode
    );
    if (data.isSuccess) {
      return data.data.RequestForm;
    }
  }

  setDatePicker(event) {
    this.generalInfo.DateFounded = event;
  }

  changeValueStatus() {
    const tempValue = this.generalInfo.SoftwareOrWBTool;
    if (tempValue === "10") {
      this.generalStatus.SoftwareOrWBTool = true;
      this.context.set("SoftwareOrWBTool", true);
    } else {
      this.generalStatus.SoftwareOrWBTool = false;
      this.context.set("SoftwareOrWBTool", false);
      // this.generalInfo.AddGeneralAttachments = []
    }
  }
}
