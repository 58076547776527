/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable prettier/prettier */
import { Injectable } from "@angular/core";
import {
  getTSMCountryServedQuery,
  supplierGeographical,
  termPaymenCodeOfCountry,
  spendCommodityGroup
} from "../../../shared/shared";
import { DynamicContext } from "../../../dynamic-components/interfaces/DynamicContext";
import { OnBoardingService } from "./onboarding.service";
import { environment } from "../../../../environments/environment";
import { AuthService } from "src/app/services/auth/auth.service";
import { DictionaryService } from "../../../services/dictionary.service";

@Injectable({
  providedIn: "root",
})
export class BuyerCommonService {
  constructor(
    private onBoardingService: OnBoardingService,
    private authService: AuthService,
    private dictionaryService: DictionaryService
  ) {}

  private isMet() {
    return (
      this.onBoardingService.supplierModel.RequestForm.RequestorDetailsInfo
        .SupplierGeographical === supplierGeographical.MET
    );
  }
  private isASEAN() {
    return (
      this.onBoardingService.supplierModel.RequestForm.RequestorDetailsInfo
        .SupplierGeographical === supplierGeographical.ASEAN
    );
  }
  private isANZ() {
    return (
      this.onBoardingService.supplierModel.RequestForm.RequestorDetailsInfo
        .SupplierGeographical === supplierGeographical.ANZ
    );
  }
  private isGreaterChina() {
    return (
      this.onBoardingService.supplierModel.RequestForm.RequestorDetailsInfo
        .SupplierGeographical === supplierGeographical.GreaterChina
    );
  }
  private isPH() {
    return (
      this.onBoardingService.supplierModel.RequestForm.RequestorDetailsInfo
        .SupplierGeographical === supplierGeographical.PH
    );
  }
  private isICEG() {
    return (
      this.onBoardingService.supplierModel.RequestForm.RequestorDetailsInfo
        .SupplierGeographical === supplierGeographical.ICEG
    );
  }
  private isCEG() {
    return (
      this.isICEG() &&
      !this.onBoardingService.supplierModel.RequestForm.RequestorDetailsInfo?.SupplierCountry.includes("IT")
    );
  }
  private isSEA() {
    return (
      this.onBoardingService.supplierModel.RequestForm.RequestorDetailsInfo
        .SupplierGeographical === supplierGeographical.SEA
    );
  }
  private isASG() {
    return (
      this.onBoardingService.supplierModel.RequestForm.RequestorDetailsInfo
        .SupplierGeographical === supplierGeographical.ASG
    );
  }
  private isUKIA() {
    return (
      this.onBoardingService.supplierModel.RequestForm.RequestorDetailsInfo
        .SupplierGeographical === supplierGeographical.UKIA
    );
  }
  private isNordics() {
    return (
      this.onBoardingService.supplierModel.RequestForm.RequestorDetailsInfo
        .SupplierGeographical === supplierGeographical.Nordics
    );
  }
  private isNA() {
    return (
      this.onBoardingService.supplierModel.RequestForm.RequestorDetailsInfo
        .SupplierGeographical === supplierGeographical.NA
    );
  }
  private isIberia() {
    return (
      this.onBoardingService.supplierModel.RequestForm.RequestorDetailsInfo
        .SupplierGeographical === supplierGeographical.Iberia
    );
  }
  private isTsmId() {
    return !!this.onBoardingService.supplierModel.Mapping.TsmId;
  }
  private TsmId() {
    return this.onBoardingService.supplierModel.Mapping.TsmId;
  }
  private isNoTsmId() {
    return !this.onBoardingService.supplierModel.Mapping.TsmId;
  }
  private isDev() {
    return environment.env == "dev";
  }
  private isTest() {
    return environment.env == "test";
  }
  private isLocal() {
    return environment.env == "local";
  }
  private isPerf() {
    return environment.env == "perf";
  }
  private isHotfix() {
    return environment.env == "hotfix";
  }
  private isProd() {
    return environment.env == "prod";
  }
  private isStage() {
    return environment.env == "stage";
  }
  private isIN() {
    return (
      this.onBoardingService.supplierModel.RequestForm.RequestorDetailsInfo
        .SupplierGeographical === supplierGeographical.IN
    );
  }
  // private isAfrica() {
  //   return (
  //     this.onBoardingService.supplierModel.RequestForm.RequestorDetailsInfo
  //       .SupplierGeographical === supplierGeographical.Africa
  //   );
  // }

  private isGallia() {
    return (this.onBoardingService.supplierModel.RequestForm.RequestorDetailsInfo
      .SupplierGeographical === supplierGeographical.Gallia
    );
  }

  private isJapan() {
    return (
      this.onBoardingService.supplierModel.RequestForm.RequestorDetailsInfo
        .SupplierGeographical === supplierGeographical.Japan
    )
  }

  private isRollout() {
    const rolloutGUs = [
      ...this.dictionaryService.getDictionaryFromCache(
        "requestor-details.SupplierGeographicalUKI"
      ),
      ...this.dictionaryService.getDictionaryFromCache(
        "requestor-details.SupplierGeographicalANZ"
      ),
    ];
    const guCode =
      this.onBoardingService.supplierModel.RequestForm.RequestorDetailsInfo
        .SupplierGeographical;
    return rolloutGUs.find(({ Code }) => guCode === Code) ? true : false;
  }
  private isGuNum() {
    const rolloutGUs = [
      ...this.dictionaryService.getDictionaryFromCache(
        "requestor-details.SupplierGeographical"
      ),
    ];
    if (rolloutGUs.length == 0) {
      return true;
    }
    const filter = rolloutGUs.filter((e) => e.Items.length != 0).length;
    return filter == 0 ? true : false;
  }
  private isPaymentModeCreditCard() {
    return (
      this.onBoardingService.supplierModel.RequestForm.RequestorDetailsInfo
        .SpendCommodityGroup=="17"
    );
  }

  private isNewSupplier() {
    return this.onBoardingService.supplierModel.isNewSupplier;
  }

  private isCam() {
    return this.onBoardingService.supplierModel.RequestForm.RequestorDetailsInfo.SpendCommodityGroup==spendCommodityGroup.CAM;
  }

  private supplierCountryList() {
    return this.onBoardingService.supplierModel.RequestForm.RequestorDetailsInfo.SupplierCountry;
  }

  private async SetBuyerModel() {
    if (
      !this.onBoardingService.supplierModel.RequestForm ||
      Object.keys(
        this.onBoardingService.supplierModel.RequestForm.RequestorDetailsInfo
      ).length == 0
    ) {
      await this.onBoardingService.loadSupplierRequestForm();
    }
  }

  private TsmLink() {
    const env = sessionStorage.getItem("tsmEnv");
    const url =
      env === "dev"
        ? environment.relationshipdev_url
        : env === "perf"
        ? environment.relationshipperf_url
        : env === "hotfix"
        ? environment.relationshiphotfix_url
        : env === "stage"
        ? environment.relationshipstage_url
        : env === "test"
        ? environment.relationshiptest_url
        : environment.relationship_url;
    const countryServed = getTSMCountryServedQuery(
      this.onBoardingService.supplierModel.Mapping.setUpCountry,
    );
    const redirectUrl =
      url +
      this.onBoardingService.supplierModel.Mapping.TsmId +
      "?countryServed=" +
      countryServed +
      "&login_hint=" +
      this.authService.passport.profile.email
    console.debug(`Jump to TSM url: ${redirectUrl}`);
    return redirectUrl;
  }

  private isInitProfile() {
    this.onBoardingService.isInitBuyerProfile();
  }

  public async SetContextValue(Context: DynamicContext) {
    await this.SetBuyerModel();
    Context.set("isMET", this.isMet());
    Context.set("isAsen", this.isASEAN());
    Context.set("isANZ", this.isANZ());
    Context.set("isGreaterChina", this.isGreaterChina());
    Context.set("isPH", this.isPH());
    Context.set("isICEG", this.isICEG());
    Context.set("isCEG", this.isCEG());
    Context.set("isSEA", this.isSEA());
    Context.set("isASG", this.isASG());
    Context.set("isUKIA", this.isUKIA());
    Context.set("isNordics", this.isNordics());
    Context.set("isNA", this.isNA());
    Context.set("TsmId", this.isTsmId());
    Context.set("TsmIdValue", this.TsmId());
    Context.set("isNoTsmId", this.isNoTsmId());
    Context.set("isLocal", this.isLocal());
    Context.set("isTest", this.isTest());
    Context.set("isDev", this.isDev());
    Context.set("isPerf", this.isPerf());
    Context.set("isHotfix", this.isHotfix());
    Context.set("isProd", this.isProd());
    Context.set("isStage", this.isStage());
    Context.set("isIN", this.isIN());
    Context.set("parentModel", this.onBoardingService.supplierModel);
    // Context.set("isAfrica", this.isAfrica());
    Context.set("TsmLink", this.TsmLink());
    Context.set("isInit", this.isInitProfile);
    Context.set("isIberia", this.isIberia());
    Context.set("isGallia", this.isGallia());
    Context.set("isRollout", this.isRollout());
    Context.set("isGuNum", this.isGuNum());
    Context.set("isJapan", this.isJapan());
    Context.set("isPaymentModeCreditCard", this.isPaymentModeCreditCard());
    Context.set("isNewSupplier", this.isNewSupplier());
    Context.set("isCam", this.isCam());
    Context.set("isRiskAssessmentNewSupplier", this.isRiskAssessmentNewSupplier());
    Context.set("supplierCountryList", this.supplierCountryList()
    )
  }
  public async SetContextSupplierProfile(Context: DynamicContext) {
    if (
      !this.onBoardingService.supplierModel.SupplierProfile ||
      Object.keys(
        this.onBoardingService.supplierModel.SupplierProfile.SupplierGeneral
      ).length === 0
    ) {
      await this.onBoardingService.loadSupplierProfile();
    }
    Context.set("parentModel", this.onBoardingService.supplierModel);
  }

  public async getTermPaymentCode() {
    await this.SetBuyerModel();
    let termPaymentCode = "";
    switch (
      this.onBoardingService.supplierModel.RequestForm.RequestorDetailsInfo
        .SupplierGeographical
    ) {
      case supplierGeographical.ANZ:
        termPaymentCode = termPaymenCodeOfCountry.ANZ;
        break;
      case supplierGeographical.ASEAN:
        termPaymentCode = termPaymenCodeOfCountry.ASEAN;
        break;
      case supplierGeographical.NA:
        termPaymentCode = termPaymenCodeOfCountry.NA;
        break;
      default:
        termPaymentCode = termPaymenCodeOfCountry.DEFAULT;
        break;
    }
    if(this.onBoardingService.supplierModel.RequestForm.RequestorDetailsInfo
      .SupplierCountry.includes("RS") || this.onBoardingService.supplierModel.RequestForm.RequestorDetailsInfo
        .SupplierCountry.includes("TR")) {
          termPaymentCode = termPaymenCodeOfCountry.TRorRS;     
    }
    return termPaymentCode;
  } 

  private isRiskAssessmentNewSupplier() {
    return this.onBoardingService.supplierModel.isRiskAssessmentNewSupplier;
  }
}
