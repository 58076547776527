import { Injectable } from "@angular/core";
import { AuditChangeRequestDetail, BuyerChangeRequestDTO } from "src/app/interfaces/changeRequest";
import { HttpService } from "src/app/services/api/http.service";
import { environment } from "src/environments/environment";
import { SupplierModel } from "src/app/interfaces/supplierModel";
import { Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class BuyerChangeRequestService {
  constructor(private http: HttpService) {}

  baseUrl = environment.gateway;

  async submitRequestor(request: BuyerChangeRequestDTO) {
    const url = `${this.baseUrl}/buyer/cr/save`;
    const result: any = await this.http.PostPromise(url, request);
    return result.isSuccess;
  }

  public qcUpdate(request: BuyerChangeRequestDTO) {
    const url = `${this.baseUrl}/buyer/cr/qcUpdate`;
    return this.http.PostPromise(url, request);
  }

  // workflow buyer router
  public buyerCR_sstApprove(
    form: SupplierModel,
    buyer_CR: BuyerChangeRequestDTO
  ) {
    const url = `${this.baseUrl}/buyer_CR/apReview/sstApprove`;
    return this.http.PostPromise(url, { form, buyer_CR });
  }

  public buyerCR_sstClose(
    form: SupplierModel,
    buyer_CR: BuyerChangeRequestDTO
  ) {
    const url = `${this.baseUrl}/buyer_CR/apReview/sstClose`;
    return this.http.PostPromise(url, { form, buyer_CR });
  }

  public buyerCR_qcApprove(
    form: SupplierModel,
    buyer_CR: BuyerChangeRequestDTO
  ) {
    const url = `${this.baseUrl}/buyer_CR/apReview/qcApprove`;
    return this.http.PostPromise(url, { form, buyer_CR });
  }

  public buyerCR_qcReject(
    form: SupplierModel,
    buyer_CR: BuyerChangeRequestDTO
  ) {
    const url = `${this.baseUrl}/buyer_CR/apReview/qcReject`;
    return this.http.PostPromise(url, { form, buyer_CR });
  }

  public buyerCR_qcClose(form: SupplierModel, buyer_CR: BuyerChangeRequestDTO) {
    const url = `${this.baseUrl}/buyer_CR/apReview/qcClose`;
    return this.http.PostPromise(url, { form, buyer_CR });
  }
}
