import * as moment from "moment";
import { RequestForm } from "./requestForm";
import { SupplierProfile } from "./supplierProfile";
import { isUndefined } from "util";
import { CurrentProcess } from "./workflow";
import { StatusModel } from "./mapping";
import { LanguageService } from "../dynamic-components/utils/language.service";
import { environment } from "src/environments/environment";
import { cloneDeep } from '../utils'

export type HistoryDataType = RequestForm | SupplierProfile;

export interface IProcess<T extends CurrentProcess> {
  process?: T;
  show?: boolean;
}
export interface IAuditTrailHistory<T extends CurrentProcess> {
  iProcess?: IProcess<T>;
  status?: string;
  statusHistories?: Array<StatusModel>;
}

// T can be request form, supplier profile
export interface IListNode<T extends HistoryDataType> {
  hash?: string;
  previous_hash?: string;
  createdOn?: string;
  createdBy?: string;
  data?: T;
}

// This field is a field that is not displayed in the page
export const ProfileFieldStatus = {
  BuyerStepTBI_BDA_1: true,
  CompanyEmailAds: true,
  AddRequestFormAttachments: true,
};

export enum FieldType {
  Text = "input",
  Object = "Object",
  Date = "Date",
  File = "uploader",
  RadioButton = "radio",
  CountryOfCollapse = "countryOfCollapse",
  PaymentBankingInformationOfCollapse = "PaymentBankingInformationOfCollapse",
  AdditionalGSTRegistrationOfCollapse = "AdditionalGSTRegistrationOfCollapse",
  ThirdPartyPaymentOptionOfCollapse = "ThirdPartyPaymentOptionOfCollapse",
}

enum HistoryStatus {
  Created = "Created",
  Updated = "Updated",
}

enum SpecialObject {
  // supplier object phone { CountryCode, AreaCode }
  PrimaryContactPhone = "PrimaryContactPhone",
  CompanyPresidentContactPhone = "CompanyPresidentContactPhone",

  // supplier object file list []
  AddGeneralAttachments = 'AddGeneralAttachments',
  UploadRegistrationDocuments = 'UploadRegistrationDocuments',
  uploadIncorporationCertificate = 'uploadIncorporationCertificate',
  UploadFinancialCopy = 'UploadFinancialCopy',
  CustomAttachments = 'CustomAttachments',
  AddFinancialAttachments = 'AddFinancialAttachments',
  AddAgreementAttachments = 'AddAgreementAttachments',
  UploadCertificate = 'UploadCertificate',
  UploadDetailsMaterial = 'UploadDetailsMaterial',
  AddDeclarationAttachments = 'AddDeclarationAttachments',
  AgreementAttachments = 'AgreementAttachments',
  ProcurementApproval = 'ProcurementApproval',
  ApproveAttachments = 'ApproveAttachments',
  UploadVatDocuments = 'UploadVatDocuments',
  EnterpriseScaleAttachments = 'EnterpriseScaleAttachments',
  SampleATPCompliant = 'SampleATPCompliant',
  SampleATPCompliant2 = 'SampleATPCompliant2',
  HaveHumanRightsAttachments = 'HaveHumanRightsAttachments',
  SignedQuotationAttachments = 'SignedQuotationAttachments',
  ThirdPartyTaxAttachments = 'ThirdPartyTaxAttachments',
  UploadCompanysAddress = 'UploadCompanysAddress',
  ThirdPartyAddFinancialAttachments = 'ThirdPartyAddFinancialAttachments',
  ThirdPartyAddressEvidenceAttachments = 'ThirdPartyAddressEvidenceAttachments',
  ThirdPartyAddAgreementAttachments = 'ThirdPartyAddAgreementAttachments',
  UploadCaRegistrationDocuments = 'UploadCaRegistrationDocuments',
  UploadRegistrationDocumentsForIndia = 'UploadRegistrationDocumentsForIndia',
  UploadCompanyRegistrationDocuments = 'UploadCompanyRegistrationDocuments',
  UploadGSTRegistrationCertificate = 'UploadGSTRegistrationCertificate',
  ContractArrangementLetterAttachments = 'ConArrLetterAttachments',
  UploadMsmeSupportingDocument = 'UploadMsmeSupportingDocument',
  W9Form = 'W9Form',
  W8Form = 'W8Form',
  UploadCORDocuments = 'UploadCORDocuments',
  IntermediaryAddFinancialAttachments = 'IntermediaryAddFinancialAttachments',
  UploadTaxResidency = 'UploadTaxResidency',
  UploadTINDocument = 'UploadTINDocument',
  UploadPANCard = 'UploadPANCard',
  UploadNOPE = 'UploadNOPE',
  UploadProvideDetails = 'UploadProvideDetails',
  MetAndNordicsUploadCertificate = 'MetAndNordicsUploadCertificate',
  AddGSTCertificateAttachments = 'AddGSTCertificateAttachments',
  AddAttachmentsByDecisionOfSupplier = 'AddAttachmentsByDecisionOfSupplier',
  AddAttachmentsByBBBEECertificate = 'AddAttachmentsByBBBEECertificate',
  AddPersonalSvcProdAttachments = 'AddPersonalSvcProdAttachments',
  UploadCompanyIncorporation = 'UploadCompanyIncorporation',
  FederalTaxDepartmentUploader = 'FederalTaxDepartmentUploader',
  PayrollTaxesCertificationUploader = 'PayrollTaxesCertificationUploader',
  LatestBalanceSheetAttachment = 'LatestBalanceSheetAttachment',
  UploadLatestStatement ='UploadLatestStatement',
  RCTOInsuranceUploader = 'RCTOInsuranceUploader',
  SupplierDataFormAttachments = 'SupplierDataFormAttachments',
  UploadEmailApproval = 'UploadEmailApproval',
  UploadEmailApprovalCN = 'UploadEmailApprovalCN',
  AntiMafiaAttachments = 'AntiMafiaAttachments',
  UploadNegotiatedContract ='UploadNegotiatedContract',
  UploadSupplierStandard = 'UploadSupplierStandard',
  SocialObligationsAttachment = 'SocialObligationsAttachment',
  SocialObligationsAttachmentPT = 'SocialObligationsAttachmentPT',
  SpanishLawAttachment = 'SpanishLawAttachment',
  PortugueseLawAttachment = 'PortugueseLawAttachment',
  SpecialEmploymentCertificate = 'SpecialEmploymentCertificate',
  UploadBusinessActivityLicense = 'UploadBusinessActivityLicense',
  ExternalFinancialReport = 'ExternalFinancialReport',
  AttachCertificate = 'AttachCertificate',
  OccupationalRiskPreventionServiceAttachment = 'OccupationalRiskPreventionServiceAttachment',
  CaterServicesAttachment = 'CaterServicesAttachment',
  LiabilityInsurance = 'LiabilityInsurance',
  SpainCertificateAttachment = 'SpainCertificateAttachment',
  OccupationalRiskPreventionServiceAttachmentPortugal = 'OccupationalRiskPreventionServiceAttachmentPortugal',
  OccupationalRiskPreventionServiceDeclarationOfDefective = 'OccupationalRiskPreventionServiceDeclarationOfDefective',
  ProvideActivityLicense = 'ProvideActivityLicense',
  RequestFormAttachments = 'RequestFormAttachments',
  uploadAnyCertification = 'uploadAnyCertification',
  ContractAttachmentsForGallia='ContractAttachmentsForGallia',
  ApplicableCriteriaOfTheLaw='ApplicableCriteriaOfTheLaw',
  ApplicableCriteriaOfTheVigilanceLaw='ApplicableCriteriaOfTheVigilanceLaw',
  EngagementActivitiesWithIntegrity = 'EngagementActivitiesWithIntegrity',
  PreventionOrManagementOfConflictOfInterests='PreventionOrManagementOfConflictOfInterests',
  InterestWhichCanCompromiseTheBusinessRelationship= 'InterestWhichCanCompromiseTheBusinessRelationship',
  UploadInterestPolicyProcedure='UploadInterestPolicyProcedure',
  uploadComplianceInformation='uploadComplianceInformation',
  AdditionalDocumentUpload = 'AdditionalDocumentUpload',
  CertificationsOfQuality = 'CertificationsOfQuality',
  UploadVendorSelfDeclaration = 'UploadVendorSelfDeclaration',
  UploadRegularAuthority = 'UploadRegularAuthority'
}

enum NoFieldName {
  CustomAttachments = "Comment field and Attachment ability if Supplier wants different payment terms than country standard." +
    "Will need to attach copy of supplier contract showing approved payment terms.",
  AgreementAttachments = "Select different payment terms if Accenture/Avanade has agreed to non-standard payment terms per executed agreement",
}
function checkoutFileExist(currentValue: any, previousValue: any) {
  const olFiles = [];
  for (const oldFile of previousValue.files) {
    let isFileExist = false;
    for (const newFile of currentValue.files) {
      if (oldFile.path === newFile.path) {
        isFileExist = true;
        break;
      }
    }
    if (!isFileExist) {
      olFiles.push(oldFile);
    }
  }
  return olFiles;
}

function getUpdatedList<T>(
  node: IListNode<T>,
  previousNode: IListNode<T>,
  historyLabel: IHistoryLabel
) {
  const historyValueList: Array<HistoryValue> = [];
  const tempProfile: any = cloneDeep(node.data);
  const previousProfile: any = cloneDeep(previousNode.data);
  for (const profileKey of Object.keys(tempProfile)) {
    const tempStep = tempProfile[profileKey];
    const previousProfileData = previousProfile[profileKey];
    // eslint-disable-next-line guard-for-in
    for (const k of Object.keys(previousProfileData)) {
      const currentHistoryLabel = historyLabel.nodes[historyLabel.index(k)];
      // After hide the multiple select, this field need to set blank but multiple select data structure is Array, So add this judge
      if (
        previousProfileData[k] instanceof Array &&
        !(
          currentHistoryLabel && currentHistoryLabel.type ===
            FieldType.AdditionalGSTRegistrationOfCollapse ||
          currentHistoryLabel && currentHistoryLabel.type ===
            FieldType.ThirdPartyPaymentOptionOfCollapse
        )
      ) {
        if (
          !Object.keys(tempStep).includes(k) ||
          (previousProfileData[k].length > 0 && !(tempStep[k].length > 0))
        ) {
          tempStep[k] = "Blank";
        }
      } else {
        if (
          !Object.keys(tempStep).includes(k) ||
          (!!previousProfileData[k] && !tempStep[k])
        ) {
          tempStep[k] = "Blank";
        }
      }
    }
    if (Object.values(tempStep).length > 0) {
      for (const k of Object.keys(tempStep)) {
        const historyValue: HistoryValue = {};
        const currentHistoryLabel = historyLabel.nodes[historyLabel.index(k)];
        if (!isUndefined(ProfileFieldStatus[k]) && ProfileFieldStatus[k]) {
          continue;
        }
        if (isUndefined(currentHistoryLabel)) {
          continue;
        }
        if (k === "PaymentTermList" && profileKey === "SupplierFinancial") {
          continue;
        }

        if (
          tempStep[k] instanceof Array &&
          (currentHistoryLabel.type === FieldType.CountryOfCollapse ||
            currentHistoryLabel.type ===
              FieldType.PaymentBankingInformationOfCollapse ||
            currentHistoryLabel.type ===
              FieldType.ThirdPartyPaymentOptionOfCollapse ||
            currentHistoryLabel.type ===
              FieldType.AdditionalGSTRegistrationOfCollapse)
        ) {
          const inList = [];
          const deleteIndex = [];
          if (previousProfileData[k]) {
            if (k && k === "PaymentBankingInformationList") {
              previousProfileData[k] = previousProfileData[k].filter(
                (item) =>
                  item.hasOwnProperty("paymentBankingInformationId") &&
                  item.paymentBankingInformationId !== "Blank"
              );
            } else {
              previousProfileData[k] = previousProfileData[k].filter(
                (item) =>
                  item[Object.keys(previousProfileData[k][0])[0]] !== "Blank"
              );
            }
          }
          for (const idIndex in tempStep[k]) {
            if (tempStep[k].length > 0) {
              if (k && k === "PaymentBankingInformationList") {
                inList.push(
                  tempStep[k][idIndex].hasOwnProperty(
                    "paymentBankingInformationId"
                  ) && tempStep[k][idIndex].paymentBankingInformationId
                );
              } else {
                inList.push(
                  tempStep[k][idIndex][Object.keys(tempStep[k][0])[0]]
                );
              }
            }
          }
          for (const dIndex in previousProfileData[k]) {
            if (
              (previousProfileData[k][dIndex].hasOwnProperty(
                "paymentBankingInformationId"
              ) &&
                inList.indexOf(
                  previousProfileData[k][dIndex].paymentBankingInformationId
                ) === -1) ||
              (previousProfileData[k][dIndex].hasOwnProperty(
                "additionalGSTRegistrationInformationId"
              ) &&
                inList.indexOf(
                  previousProfileData[k][dIndex][
                    Object.keys(previousProfileData[k][0])[0]
                  ]
                ) === -1) ||
              (previousProfileData[k][dIndex].hasOwnProperty(
                "thirdPartyPaymentInformationId"
              ) &&
                inList.indexOf(
                  previousProfileData[k][dIndex][
                    Object.keys(previousProfileData[k][0])[0]
                  ]
                ) === -1)
            ) {
              deleteIndex.push(dIndex);
            }
          }
          const temVlaue = {};
          // eslint-disable-next-line guard-for-in
          for (const index in previousProfileData[k]) {
            const temVlaueFlag = {};
            for (const j of Object.keys(previousProfileData[k][index])) {
              if (
                currentHistoryLabel.type ===
                  FieldType.AdditionalGSTRegistrationOfCollapse ||
                currentHistoryLabel.type ===
                  FieldType.ThirdPartyPaymentOptionOfCollapse
              ) {
                tempStep[k]
                  .filter(
                    (itemTemp) =>
                      !isUndefined(itemTemp) &&
                      (previousProfileData[k][index]
                        .additionalGSTRegistrationInformationId ===
                        itemTemp.additionalGSTRegistrationInformationId ||
                        previousProfileData[k][index]
                          .thirdPartyPaymentInformationId ===
                          itemTemp.thirdPartyPaymentInformationId)
                  )
                  .map((item) => {
                    if (
                      !isUndefined(item) &&
                      (!Object.keys(item).includes(j) ||
                        (!!previousProfileData[k][index][j] && !item[j]) ||
                        (!!previousProfileData[k][index][j].length &&
                          !item[j].length))
                    ) {
                      item[j] = "Blank";
                    }
                  });
                if (deleteIndex.includes(index)) {
                  if (previousProfileData[k][index][j]) {
                    temVlaueFlag[j] = "Blank";
                  }
                }
              } else {
                if (!isUndefined(tempStep[k][index])) {
                  if (
                    !Object.keys(tempStep[k][index]).includes(j) ||
                    (!!previousProfileData[k][index][j] &&
                      !tempStep[k][index][j])
                  ) {
                    tempStep[k][index][j] = "Blank";
                  }
                }
                temVlaue[j] = "Blank";
              }
            }
            if (Object.keys(temVlaueFlag).length) {
              temVlaue[index] = temVlaueFlag;
            }
          }
          if (deleteIndex.length > 0) {
            // eslint-disable-next-line guard-for-in
            for (const index in deleteIndex) {
              // console.log(node.hash + "$$$$$$$$$$$$$$$$$$$" + deleteIndex);
              if (
                currentHistoryLabel.type ===
                  FieldType.AdditionalGSTRegistrationOfCollapse ||
                currentHistoryLabel.type ===
                  FieldType.ThirdPartyPaymentOptionOfCollapse
              ) {
                tempStep[k].splice(
                  +deleteIndex[index],
                  0,
                  temVlaue[deleteIndex[index]]
                );
              } else {
                tempStep[k].splice(+deleteIndex[index], 0, temVlaue);
              }
            }
          }
          // eslint-disable-next-line guard-for-in
          for (const index in tempStep[k]) {
            let tagId = "";
            for (const j of Object.keys(tempStep[k][index])) {
              if (
                j.includes("PaymentTermId") ||
                j.includes("paymentBankingInformationId") ||
                j.includes("thirdPartyPaymentInformationId") ||
                j.includes("thirdPartyPaymentId") ||
                j.includes("additionalGSTRegistrationInformationId") ||
                j.includes("additionalGstRegistrationInformationId")
              ) {
                tagId = tempStep[k][index][j];
                continue;
              }
              if (j.includes("LanguageKey")) {
                continue;
              }
              const tempPreviousStep = previousNode.data[profileKey];
              let previousValue: any = "";
              const historyValue: HistoryValue = { tagId };
              let currentValue: any;
              const country = tempStep[k][index].Country;
              const companyCode = tempStep[k][index].CompanyCode;
              if (
                Object.keys(tempPreviousStep).length > 0 &&
                tempPreviousStep.hasOwnProperty(k)
              ) {
                if (tempPreviousStep[k].length < tempStep[k].length) {
                  if (k === "PaymentTermList") {
                    tempPreviousStep[k]
                      .filter(
                        (itemPre) =>
                          tempStep[k][index].PaymentTermId ===
                          itemPre.PaymentTermId
                      )
                      .map((item) => {
                        previousValue = transform(
                          item[j],
                          j,
                          historyLabel.nodes[historyLabel.index(j)].type
                        );
                      });
                    currentValue = transform(
                      tempStep[k][index][j],
                      j,
                      historyLabel.nodes[historyLabel.index(j)].type
                    );
                  } else {
                    if (index < tempPreviousStep[k].length) {
                      previousValue = transform(
                        tempPreviousStep[k][index][j],
                        j,
                        historyLabel.nodes[historyLabel.index(j)].type
                      );
                    }
                    currentValue = transform(
                      tempStep[k][index][j],
                      j,
                      historyLabel.nodes[historyLabel.index(j)].type
                    );
                  }
                }
                if (tempPreviousStep[k].length === tempStep[k].length) {
                  previousValue = transform(
                    tempPreviousStep[k][index][j],
                    j,
                    historyLabel.nodes[historyLabel.index(j)].type
                  );
                  currentValue = transform(
                    tempStep[k][index][j],
                    j,
                    historyLabel.nodes[historyLabel.index(j)].type
                  );
                }
                if (tempPreviousStep[k].length > tempStep[k].length) {
                  previousValue = transform(
                    tempPreviousStep[k][index][j],
                    j,
                    historyLabel.nodes[historyLabel.index(j)].type
                  );
                  if (index < tempStep[k].length) {
                    currentValue = transform(
                      tempStep[k][index][j],
                      j,
                      historyLabel.nodes[historyLabel.index(j)].type
                    );
                  }
                }
                if (
                  previousValue === "" &&
                  !!currentValue &&
                  !currentValue.isFile
                ) {
                  previousValue = "Blank";
                }
              } else if (!j.includes("PaymentTermId")) {
                currentValue = transform(
                  tempStep[k][index][j],
                  j,
                  historyLabel.nodes[historyLabel.index(j)].type
                );
              }
              // const previousProfileData = tempPreviousStep[k][index]
              // currentValue.tagId = tagId
              if (
                currentValue &&
                currentValue.isFile &&
                currentValue.path !== previousValue.path
              ) {
                historyValue.newFiles = currentValue.files;
                if (previousValue !== "") {
                  if (previousValue.files.length > 0) {
                    historyValue.oldFiles = checkoutFileExist(
                      currentValue,
                      previousValue
                    );
                  }
                }

                historyValue.isFile = true;
                historyValue.columName = j;
                historyValue.fieldType =
                  historyLabel.nodes[historyLabel.index(j)].type;
                historyValue.formType =
                  historyLabel.nodes[historyLabel.index(j)].MainTitle;
                if (
                  Object.keys(tempPreviousStep).length > 0 &&
                  tempPreviousStep.hasOwnProperty(k)
                ) {
                  if (
                    tempPreviousStep[k][index] &&
                    Object.values(tempPreviousStep[k][index]).length > 1
                  ) {
                    historyValue.status = HistoryStatus.Updated;
                  } else {
                    historyValue.status = HistoryStatus.Created;
                  }
                } else {
                  historyValue.status = HistoryStatus.Created;
                }

                if (historyLabel.nodes[historyLabel.index(j)].filters) {
                  historyValue.filters =
                    historyLabel.nodes[historyLabel.index(j)].filters;
                }
                if (historyLabel.nodes[historyLabel.index(j)].hooks) {
                  historyValue.hooks =
                    historyLabel.nodes[historyLabel.index(j)].hooks;
                }
                if (historyLabel.nodes[historyLabel.index(j)].hide) {
                  historyValue.hide =
                    historyLabel.nodes[historyLabel.index(j)].hide;
                }

                historyValue.title =
                  historyLabel.nodes[historyLabel.index(j)].label;
                historyValue.languageKey =
                  historyLabel.nodes[historyLabel.index(j)].languageKey;
                historyValue.newValue = currentValue;
                historyValue.oldValue = previousValue;

                historyValueList.push(historyValue);
                continue;
              }
              // Normal field compare || j==='Country'||j==='CompanyCode'
              if (
                currentValue &&
                currentValue.length > 0 &&
                currentValue !== previousValue
              ) {
                if (
                  Object.keys(tempPreviousStep).length > 0 &&
                  tempPreviousStep.hasOwnProperty(k)
                ) {
                  if (tempPreviousStep[k].length === tempStep[k].length) {
                    // eslint-disable-next-line max-len
                    if (
                      Object.values(tempPreviousStep[k][index]).length === 1 &&
                      (Object.keys(tempPreviousStep[k][index])[0] ===
                        "BuyerStepTBI_BDA_1" ||
                        Object.keys(previousProfileData)[0] ===
                          "PaymentTermsField")
                    ) {
                      historyValue.status = HistoryStatus.Created;
                      historyValue.title =
                        historyLabel.nodes[historyLabel.index(j)].label;
                      historyValue.languageKey =
                        historyLabel.nodes[historyLabel.index(j)].languageKey;
                    } else if (
                      Object.values(tempPreviousStep[k][index]).length > 0 &&
                      Object.keys(tempPreviousStep[k][index])[0] !==
                        "BuyerStepTBI_BDA_1"
                    ) {
                      historyValue.status = HistoryStatus.Updated;
                      historyValue.title =
                        historyLabel.nodes[historyLabel.index(j)].label;
                      historyValue.languageKey =
                        historyLabel.nodes[historyLabel.index(j)].languageKey;
                    } else {
                      historyValue.title =
                        historyLabel.nodes[historyLabel.index(j)].label;
                      historyValue.languageKey =
                        historyLabel.nodes[historyLabel.index(j)].languageKey;
                      historyValue.status = HistoryStatus.Created;
                    }
                  } else {
                    historyValue.status = HistoryStatus.Updated;
                    historyValue.title =
                      historyLabel.nodes[historyLabel.index(j)].label;
                    historyValue.languageKey =
                      historyLabel.nodes[historyLabel.index(j)].languageKey;
                  }
                } else {
                  historyValue.title =
                    historyLabel.nodes[historyLabel.index(j)].label;
                  historyValue.languageKey =
                    historyLabel.nodes[historyLabel.index(j)].languageKey;
                  historyValue.status = HistoryStatus.Created;
                }

                if (historyLabel.nodes[historyLabel.index(j)].filters) {
                  historyValue.filters =
                    historyLabel.nodes[historyLabel.index(j)].filters;
                }
                if (historyLabel.nodes[historyLabel.index(j)].hooks) {
                  historyValue.hooks =
                    historyLabel.nodes[historyLabel.index(j)].hooks;
                }
                if (historyLabel.nodes[historyLabel.index(j)].hide) {
                  historyValue.hide =
                    historyLabel.nodes[historyLabel.index(j)].hide;
                }
                if (historyLabel.nodes[historyLabel.index(j)].options) {
                  historyValue.options =
                    historyLabel.nodes[historyLabel.index(j)].options;
                }
                if (
                  historyLabel.nodes[historyLabel.index(j)].dataSource &&
                  (j === "CountryLocation" ||
                    j === "TermPayment" ||
                    j === "BankCountry" ||
                    j === "CurrencyUsedForPayments" ||
                    j === "ThirdPartyBankCountry" ||
                    j === "ThirdPartyCurrencyUsedForPayments" ||
                    j === "IntermediaryBankCountry" ||
                    j === "IntermediaryCurrencyUsedForPayments" ||
                    j === "iSHaveIntermediaryBankAccount" ||
                    j === "isPESONetregistered" ||
                    j === "isOtherPESONetregistered" ||
                    j === "AdditionalState" ||
                    j === "AdditionalGSTVendor")
                ) {
                  historyValue.dataSource =
                    historyLabel.nodes[historyLabel.index(j)].dataSource;
                }
                historyValue.isFile = false;
                // historyValue.title = historyLabel.nodes[historyLabel.index(j)].label
                if (
                  tempStep["PaymentBankingInformationList"] &&
                  tempStep["PaymentBankingInformationList"].length > 0
                ) {
                  historyValue.formType =
                    historyLabel.nodes[
                      historyLabel.index("PaymentBankingInformationList")
                    ].MainTitle;
                } else {
                  historyValue.formType =
                    historyLabel.nodes[historyLabel.index(k)].MainTitle;
                }
                if (
                  tempStep["ThirdPartyPaymentInformationList"] &&
                  tempStep["ThirdPartyPaymentInformationList"].length > 0
                ) {
                  historyValue.formType =
                    historyLabel.nodes[
                      historyLabel.index("ThirdPartyPaymentInformationList")
                    ].MainTitle;
                } else {
                  historyValue.formType =
                    historyLabel.nodes[historyLabel.index(k)].MainTitle;
                }
                if (
                  tempStep["AdditionalGSTRegistrationList"] &&
                  tempStep["AdditionalGSTRegistrationList"].length > 0
                ) {
                  historyValue.formType =
                    historyLabel.nodes[
                      historyLabel.index("AdditionalGSTRegistrationList")
                    ].MainTitle;
                } else {
                  historyValue.formType =
                    historyLabel.nodes[historyLabel.index(k)].MainTitle;
                }
                historyValue.newValue = currentValue;
                historyValue.columName = j;
                historyValue.fieldType =
                  historyLabel.nodes[historyLabel.index(j)].type;
                historyValue.oldValue = previousValue;
              }

              if (Object.values(historyValue) && !!historyValue.status) {
                historyValueList.push(historyValue);
              }
            }
          }
        } else {
          const currentValue: any = transform(
            tempStep[k],
            k,
            currentHistoryLabel.type
          );
          const tempPreviousStep = previousNode.data[profileKey];
          const previousValue = transform(
            tempPreviousStep[k],
            k,
            currentHistoryLabel.type
          );
          if (k === "CustomAttachments") {
          }
          if (
            currentValue &&
            currentValue.isFile &&
            currentValue.path !== previousValue.path
          ) {
            historyValue.newFiles = currentValue.files;
            if (previousValue !== "") {
              if (previousValue.files && previousValue.files.length > 0) {
                historyValue.oldFiles = checkoutFileExist(
                  currentValue,
                  previousValue
                );
              }
            }

            historyValue.isFile = true;
            historyValue.columName = k;
            historyValue.fieldType = currentHistoryLabel.type;
            historyValue.formType = currentHistoryLabel.MainTitle;
            if (Object.values(previousProfileData).length > 1) {
              historyValue.status = HistoryStatus.Updated;
            } else {
              historyValue.status = HistoryStatus.Created;
            }
            if (currentHistoryLabel.filters) {
              historyValue.filters = currentHistoryLabel.filters;
            }
            if (currentHistoryLabel.hooks) {
              historyValue.hooks = currentHistoryLabel.hooks;
            }
            if (currentHistoryLabel.hide) {
              historyValue.hide = currentHistoryLabel.hide;
            }

            historyValue.title = currentHistoryLabel.label;
            historyValue.languageKey = currentHistoryLabel.languageKey;
            historyValue.newValue = currentValue;
            historyValue.oldValue = previousValue;

            historyValueList.push(historyValue);

            // if (currentValue.files.length > 0) {
            // }
            continue;
          }
          // Normal field compare
          if (
            currentValue &&
            currentValue.length > 0 &&
            currentValue !== previousValue
          ) {
            if (
              Object.values(previousProfileData).length === 1 &&
              (Object.keys(previousProfileData)[0] === "BuyerStepTBI_BDA_1" ||
                Object.keys(previousProfileData)[0] === "PaymentTermsField")
            ) {
              historyValue.status = HistoryStatus.Created;
            } else if (
              Object.values(previousProfileData).length === 1 &&
              Object.keys(previousProfileData)[0] === "RequestorEmailAddress"
            ) {
              historyValue.status = HistoryStatus.Created;
            } else if (
              Object.values(previousProfileData).length > 0 &&
              Object.keys(previousProfileData)[0] !== "BuyerStepTBI_BDA_1"
            ) {
              historyValue.status = HistoryStatus.Updated;
            } else {
              historyValue.status = HistoryStatus.Created;
            }
            if (currentHistoryLabel.filters) {
              historyValue.filters = currentHistoryLabel.filters;
            }
            if (currentHistoryLabel.hooks) {
              historyValue.hooks = currentHistoryLabel.hooks;
            }
            if (currentHistoryLabel.hide) {
              historyValue.hide = currentHistoryLabel.hide;
            }
            if (currentHistoryLabel.options) {
              historyValue.options = currentHistoryLabel.options;
            }
            if (currentHistoryLabel.dataSource) {
              historyValue.dataSource = currentHistoryLabel.dataSource;
            }
            historyValue.isFile = false;
            historyValue.title = currentHistoryLabel.label;
            historyValue.languageKey = currentHistoryLabel.languageKey;
            historyValue.formType = currentHistoryLabel.MainTitle;
            historyValue.newValue = currentValue;
            historyValue.columName = k;
            historyValue.fieldType = currentHistoryLabel.type;
            historyValue.oldValue = previousValue;
          }
          if (Object.values(historyValue).length > 0) {
            historyValueList.push(historyValue);
          }
        }
      }
    }
  }
  return historyValueList;
}

function getCreatedList<T>(node: IListNode<T>, historyLabel: IHistoryLabel) {
  const historyValueList: Array<HistoryValue> = [];
  const tempProfile: any = node.data;
  if (isUndefined(tempProfile)) {
    return historyValueList;
  }
  for (const profileKey of Object.keys(tempProfile)) {
    const tempStep = tempProfile[profileKey];
    if (Object.values(tempStep).length > 0) {
      for (const k of Object.keys(tempStep)) {
        const historyValue: HistoryValue = {};
        const currentHistoryLabel = historyLabel.nodes[historyLabel.index(k)];

        if (!isUndefined(ProfileFieldStatus[k]) && ProfileFieldStatus[k]) {
          continue;
        }
        if (isUndefined(currentHistoryLabel)) {
          continue;
        }
        if (
          tempStep && tempStep[k] && tempStep[k].length > 0 &&
          (currentHistoryLabel.type === FieldType.CountryOfCollapse ||
            currentHistoryLabel.type ===
              FieldType.PaymentBankingInformationOfCollapse ||
            currentHistoryLabel.type ===
              FieldType.ThirdPartyPaymentOptionOfCollapse ||
            currentHistoryLabel.type ===
              FieldType.AdditionalGSTRegistrationOfCollapse)
        ) {
          // eslint-disable-next-line guard-for-in
          for (const index in tempStep[k]) {
            for (const j of Object.keys(tempStep[k][index])) {
              // eslint-disable-next-line @typescript-eslint/no-shadow
              const historyValue: HistoryValue = {};
              let currentValue: any = "";
              if (historyLabel.index(j) == -1) {
                continue;
              }
              currentValue = transform(
                tempStep[k][index][j],
                j,
                historyLabel.nodes[historyLabel.index(j)].type
              );
              // const previousProfileData = tempPreviousStep[k][index]

              // const previousValue = transform(tempPreviousStep[k], k, currentHistoryLabel.type)
              if (currentValue && currentValue.isFile) {
                historyValue.isFile = true;
                historyValue.columName = j;
                historyValue.fieldType =
                  historyLabel.nodes[historyLabel.index(j)].type;
                historyValue.formType =
                  historyLabel.nodes[historyLabel.index(j)].MainTitle;
                historyValue.title =
                  historyLabel.nodes[historyLabel.index(j)].label;
                historyValue.languageKey =
                  historyLabel.nodes[historyLabel.index(j)].languageKey;

                historyValue.newFiles = currentValue.files;

                if (historyLabel.nodes[historyLabel.index(j)].filters) {
                  historyValue.filters =
                    historyLabel.nodes[historyLabel.index(j)].filters;
                }
                if (historyLabel.nodes[historyLabel.index(j)].hooks) {
                  historyValue.hooks =
                    historyLabel.nodes[historyLabel.index(j)].hooks;
                }
                if (historyLabel.nodes[historyLabel.index(j)].hide) {
                  historyValue.hide =
                    historyLabel.nodes[historyLabel.index(j)].hide;
                }
                if (currentValue.files.length > 0) {
                  historyValueList.push(historyValue);
                }
                continue;
              } else {
                // Normal field compare || j==='Country'||j==='CompanyCode'
                historyValue.isFile = false;
                historyValue.title =
                  historyLabel.nodes[historyLabel.index(j)].label;
                historyValue.languageKey =
                  historyLabel.nodes[historyLabel.index(j)].languageKey;
                historyValue.formType =
                  historyLabel.nodes[historyLabel.index(k)].MainTitle;
                historyValue.newValue = currentValue;
                historyValue.columName = j;
                historyValue.fieldType =
                  historyLabel.nodes[historyLabel.index(j)].type;
                if (historyLabel.nodes[historyLabel.index(j)].filters) {
                  historyValue.filters =
                    historyLabel.nodes[historyLabel.index(j)].filters;
                }
                if (historyLabel.nodes[historyLabel.index(j)].hooks) {
                  historyValue.hooks =
                    historyLabel.nodes[historyLabel.index(j)].hooks;
                }
                if (historyLabel.nodes[historyLabel.index(j)].hide) {
                  historyValue.hide =
                    historyLabel.nodes[historyLabel.index(j)].hide;
                }
                if (historyLabel.nodes[historyLabel.index(j)].options) {
                  historyValue.options =
                    historyLabel.nodes[historyLabel.index(j)].options;
                }
                if (
                  historyLabel.nodes[historyLabel.index(j)].dataSource &&
                  (j === "CountryLocation" ||
                    j === "TermPayment" ||
                    j === "BankCountry" ||
                    j === "CurrencyUsedForPayments" ||
                    j === "ThirdPartyBankCountry" ||
                    j === "ThirdPartyCurrencyUsedForPayments" ||
                    j === "AdditionalState" ||
                    j === "AdditionalGSTVendor")
                ) {
                  historyValue.dataSource =
                    historyLabel.nodes[historyLabel.index(j)].dataSource;
                }
              }

              if (
                Object.values(historyValue).length > 0 &&
                historyValue.title !== "PaymentTermId"
              ) {
                historyValueList.push(historyValue);
              }
            }
          }
        } else {
          const currentValue: any = transform(
            tempStep[k],
            k,
            currentHistoryLabel.type
          );

          if (currentValue && currentValue.isFile) {
            historyValue.title = currentHistoryLabel.label;
            historyValue.languageKey = currentHistoryLabel.languageKey;
            historyValue.newFiles = currentValue.files;
            historyValue.isFile = true;
            historyValue.columName = k;
            historyValue.fieldType = currentHistoryLabel.type;
            if (currentHistoryLabel.filters) {
              historyValue.filters = currentHistoryLabel.filters;
            }
            if (currentHistoryLabel.hooks) {
              historyValue.hooks = currentHistoryLabel.hooks;
            }
            if (currentHistoryLabel.hide) {
              historyValue.hide = currentHistoryLabel.hide;
            }
            if (currentValue.files.length > 0) {
              historyValueList.push(historyValue);
            }
            continue;
          }

          historyValue.isFile = false;
          historyValue.columName = k;
          historyValue.fieldType = currentHistoryLabel.type;
          historyValue.title = currentHistoryLabel.label;
          historyValue.languageKey = currentHistoryLabel.languageKey;
          historyValue.formType = currentHistoryLabel.MainTitle;
          historyValue.newValue = currentValue;
          if (currentHistoryLabel.filters) {
            historyValue.filters = currentHistoryLabel.filters;
          }
          if (currentHistoryLabel.hooks) {
            historyValue.hooks = currentHistoryLabel.hooks;
          }
          if (currentHistoryLabel.hide) {
            historyValue.hide = currentHistoryLabel.hide;
          }
          if (currentHistoryLabel.options) {
            historyValue.options = currentHistoryLabel.options;
          }
          if (currentHistoryLabel.dataSource) {
            historyValue.dataSource = currentHistoryLabel.dataSource;
          }
          if (Object.values(historyValue).length > 0) {
            historyValueList.push(historyValue);
          }
        }
      }
    }
  }
  return historyValueList;
}

function transform(value: any, key: string, type: string) {
  if (type === FieldType.Object) {
    if(key === SpecialObject.PrimaryContactPhone || key === SpecialObject.CompanyPresidentContactPhone) {
      if (!!value && value.CountryCode && value.AreaCode) {
        return value.CountryCode + " - " + value.AreaCode;
      } else {
        return ""
      }
    }
  } else if (type === FieldType.File) {
    if (key === SpecialObject.AddDeclarationAttachments
      || key === SpecialObject.AddFinancialAttachments
      || key === SpecialObject.AddAgreementAttachments
      || key === SpecialObject.AddGeneralAttachments
      || key === SpecialObject.CustomAttachments
      || key === SpecialObject.UploadCertificate
      || key === SpecialObject.AgreementAttachments
      || key === SpecialObject.ProcurementApproval
      || key === SpecialObject.UploadDetailsMaterial
      || key === SpecialObject.UploadFinancialCopy
      || key === SpecialObject.HaveHumanRightsAttachments
      || key === SpecialObject.UploadRegistrationDocuments
      || key === SpecialObject.uploadIncorporationCertificate
      || key === SpecialObject.ApproveAttachments
      || key === SpecialObject.EnterpriseScaleAttachments
      || key === SpecialObject.UploadVatDocuments
      || key === SpecialObject.SampleATPCompliant
      || key === SpecialObject.SampleATPCompliant2
      || key === SpecialObject.SignedQuotationAttachments
      || key === SpecialObject.ThirdPartyTaxAttachments
      || key === SpecialObject.UploadCompanysAddress
      || key === SpecialObject.ThirdPartyAddFinancialAttachments
      || key === SpecialObject.ThirdPartyAddressEvidenceAttachments
      || key === SpecialObject.ThirdPartyAddAgreementAttachments
      || key === SpecialObject.UploadCaRegistrationDocuments
      || key === SpecialObject.UploadRegistrationDocumentsForIndia
      || key === SpecialObject.UploadCompanyRegistrationDocuments
      || key === SpecialObject.UploadGSTRegistrationCertificate
      || key === SpecialObject.ContractArrangementLetterAttachments
      || key === SpecialObject.W9Form
      || key === SpecialObject.W8Form
      || key === SpecialObject.UploadCORDocuments
      || key === SpecialObject.UploadMsmeSupportingDocument
      || key === SpecialObject.UploadTaxResidency
      || key === SpecialObject.UploadTINDocument
      || key === SpecialObject.UploadPANCard
      || key === SpecialObject.UploadNOPE
      || key === SpecialObject.IntermediaryAddFinancialAttachments
      || key === SpecialObject.IntermediaryAddFinancialAttachments
      || key === SpecialObject.UploadProvideDetails
      || key === SpecialObject.MetAndNordicsUploadCertificate
      || key === SpecialObject.AddGSTCertificateAttachments
      || key === SpecialObject.AddAttachmentsByDecisionOfSupplier
      || key === SpecialObject.AddAttachmentsByBBBEECertificate
      || key === SpecialObject.AddPersonalSvcProdAttachments
      || key === SpecialObject.UploadCompanyIncorporation
      || key === SpecialObject.FederalTaxDepartmentUploader
      || key === SpecialObject.PayrollTaxesCertificationUploader
      || key === SpecialObject.LatestBalanceSheetAttachment
      || key === SpecialObject.UploadLatestStatement
      || key === SpecialObject.RCTOInsuranceUploader
      || key === SpecialObject.SupplierDataFormAttachments
      || key === SpecialObject.UploadEmailApproval
      || key === SpecialObject.UploadEmailApprovalCN
      || key === SpecialObject.AntiMafiaAttachments
      || key === SpecialObject.UploadNegotiatedContract
      || key === SpecialObject.UploadSupplierStandard
      || key === SpecialObject.SocialObligationsAttachment
      || key === SpecialObject.SocialObligationsAttachmentPT
      || key === SpecialObject.SpanishLawAttachment
      || key === SpecialObject.PortugueseLawAttachment
      || key === SpecialObject.SpecialEmploymentCertificate
      || key === SpecialObject.UploadBusinessActivityLicense
      || key === SpecialObject.ExternalFinancialReport
      || key === SpecialObject.AttachCertificate
      || key === SpecialObject.SpecialEmploymentCertificate
      || key === SpecialObject.OccupationalRiskPreventionServiceAttachment
      || key === SpecialObject.CaterServicesAttachment
      || key === SpecialObject.SpainCertificateAttachment
      || key === SpecialObject.LiabilityInsurance
      || key === SpecialObject.OccupationalRiskPreventionServiceAttachmentPortugal
      || key === SpecialObject.OccupationalRiskPreventionServiceDeclarationOfDefective
      || key === SpecialObject.ProvideActivityLicense
      || key === SpecialObject.RequestFormAttachments
      || key === SpecialObject.uploadAnyCertification
      || key === SpecialObject.ContractAttachmentsForGallia
      || key === SpecialObject.ApplicableCriteriaOfTheLaw
      || key === SpecialObject.ApplicableCriteriaOfTheVigilanceLaw
      || key === SpecialObject.EngagementActivitiesWithIntegrity
      || key === SpecialObject.PreventionOrManagementOfConflictOfInterests
      || key === SpecialObject.InterestWhichCanCompromiseTheBusinessRelationship
      || key === SpecialObject.UploadInterestPolicyProcedure
      || key === SpecialObject.uploadComplianceInformation
      || key === SpecialObject.AdditionalDocumentUpload
      || key === SpecialObject.CertificationsOfQuality
      || key === SpecialObject.UploadVendorSelfDeclaration
      || key === SpecialObject.UploadRegularAuthority)
    {

      const ret: any = {}
      ret.files = []
      ret.isFile = true
      ret.path = ''
      if (value === 'Blank') {
        return 'Blank'
      }
      if (value && value.length > 0) {
        value.forEach((e) => {
          if (e) {
            const file = { name: e.name, key: e.key, path: e.filePath };
            ret.path += e.filePath;
            ret.files.push(file);
          }
        });
      }
      return ret;
    }
  } else if (type === FieldType.Date) {
    if (value) {
      if (value === "Blank") {
        return "Blank";
      }
      return moment(value).format("DD MMM YYYY");
    }
  } else if (type === FieldType.RadioButton) {
    return value;
  } else {
    if (value && !isUndefined(value)) {
      return value.toString();
    } else {
      return value;
    }
  }
}

export class ILinkedList<T> {
  nodes: Array<IListNode<T>> = [];

  index(node: IListNode<T>): number {
    const index = this.nodes.findIndex((n) => n.hash === node.previous_hash);
    return index;
  }

  last(): IListNode<T> {
    const last = this.nodes[this.nodes.length - 1];

    if (last) {
      return last;
    }
  }

  compare(node: IListNode<T>, historyLabel: IHistoryLabel) {
    if (node.previous_hash) {
      const previousNode = this.previous(node);
      return getUpdatedList<T>(node, previousNode, historyLabel);
    } else {
      return getCreatedList<T>(node, historyLabel);
    }
  }

  previous(node: IListNode<T>): IListNode<T> {
    const index = this.nodes.findIndex((n) => n.hash === node.previous_hash);
    if (index === -1) {
      return undefined;
    }
    return this.nodes[index];
  }

  next(node: IListNode<T>): IListNode<T> {
    const index = this.nodes.findIndex((n) => n.previous_hash === node.hash);
    if (index === -1) {
      return undefined;
    }
    return this.nodes[index];
  }

  toJSON(node: IListNode<T>): string {
    return JSON.stringify(node);
  }
}

export class IHistoryLabel {
  nodes: Array<any> = [];

  index(key: string): number {
    const index = this.nodes.findIndex((n: any) => {
      return n.name === key;
    });
    return index;
  }
}

interface HistoryValue {
  title?: string;
  formType?: string;
  newValue?: string;
  oldValue?: string;
  status?: string;
  isFile?: boolean;
  newFiles?: Array<any>;
  path?: string;
  oldFiles?: Array<string>;
  columName?: string;
  fieldType?: string;
  filters?: any;
  hooks?: any;
  options?: any;
  dataSource?: any;
  tagId?: string;
  languageKey?: string;
  hide?: boolean;
}

export interface IHistoryItem {
  txId?: string;
  date?: string;
  supplierUser?: string;
  show?: boolean;
  value?: Array<HistoryValue>;
  IsUpdate?: boolean;
  typeInfo?: string;
  type?: string;
}

export class IListHistoryItems<T extends HistoryDataType> {
  nodes: Array<IHistoryItem> = [];
  linkedList: ILinkedList<T> = new ILinkedList();
  historyLabel: IHistoryLabel = new IHistoryLabel();
  By: string = this.languageService.getValue(
    "static.supplier.history_supplier.By"
  );
  Created: string = this.languageService.getValue(
    "static.supplier.history_supplier.Created"
  );
  Created_By: string = this.languageService.getValue(
    "static.supplier.history_supplier.Created_By"
  );
  constructor(
    list: Array<IListNode<T>>,
    historyLabels: Array<any>,
    private languageService: LanguageService
  ) {
    this.linkedList.nodes = list;
    this.historyLabel.nodes = historyLabels;
  }
  statusLanguage(status?: string) {
    switch (status) {
      case "Updated":
        status = this.languageService.getValue(
          "static.supplier.history_supplier.Updated"
        );
        break;
      case "Created":
        status = this.languageService.getValue(
          "static.supplier.history_supplier.Created"
        );
        break;
    }
    return status;
  }

  fieldOrder(typeInfo?: string, status?: string, formType?: string) {
    switch (status) {
      case "Created":
        typeInfo = this.fieldOrder_created(typeInfo, formType);
        break;
      case "Updated":
        typeInfo = this.fieldOrder_updated(typeInfo, formType);
        break;
    }
    return typeInfo;
  }

  fieldOrder_created(typeInfo?: string, formType?: string) {
    switch (formType) {
      case "General":
        typeInfo = this.languageService.getValue(
          "static.supplier.history_supplier.General_Created"
        );
        break;
      case "Organization":
        typeInfo = this.languageService.getValue(
          "static.supplier.history_supplier.Organization_Created"
        );
        break;
      case "Financial":
        typeInfo = this.languageService.getValue(
          "static.supplier.history_supplier.Financial_Created"
        );
        break;
      case "Compliance":
        typeInfo = this.languageService.getValue(
          "static.supplier.history_supplier.Compliance_Created"
        );
        break;
      case "Supplier-Declaration":
        typeInfo = this.languageService.getValue(
          "static.supplier.history_supplier.Supplier_Declaration_Created"
        );
        break;
      case "Requestor Details":
        typeInfo = this.languageService.getValue(
          "static.supplier.history_supplier.Requestor_Details_Created"
        );
        break;
      case "Supplier & Spend Details":
        typeInfo = this.languageService.getValue(
          "static.supplier.history_supplier.Supplier&Spend_Details_Created"
        );
        break;
      case "Risk Assessment":
        typeInfo = this.languageService.getValue(
          "static.supplier.history_supplier.Risk_Assessment_Created"
        );
        break;
      case "Declaration":
        typeInfo = this.languageService.getValue(
          "static.supplier.history_supplier.Declaration_Created"
        );
        break;
      default:
        typeInfo = "";
        break;
    }
    return typeInfo;
  }

  fieldOrder_updated(typeInfo?: string, formType?: string) {
    switch (formType) {
      case "General":
        typeInfo = this.languageService.getValue(
          "static.supplier.history_supplier.General_Updated"
        );
        break;
      case "Organization":
        typeInfo = this.languageService.getValue(
          "static.supplier.history_supplier.Organization_Updated"
        );
        break;
      case "Financial":
        typeInfo = this.languageService.getValue(
          "static.supplier.history_supplier.Financial_Updated"
        );
        break;
      case "Compliance":
        typeInfo = this.languageService.getValue(
          "static.supplier.history_supplier.Compliance_Updated"
        );
        break;
      case "Supplier-Declaration":
        typeInfo = this.languageService.getValue(
          "static.supplier.history_supplier.Supplier_Declaration_Updated"
        );
        break;
      case "Requestor Details":
        typeInfo = this.languageService.getValue(
          "static.supplier.history_supplier.Requestor_Details_Updated"
        );
        break;
      case "Supplier & Spend Details":
        typeInfo = this.languageService.getValue(
          "static.supplier.history_supplier.Supplier&Spend_Details_Updated"
        );
        break;
      case "Risk Assessment":
        typeInfo = this.languageService.getValue(
          "static.supplier.history_supplier.Risk_Assessment_Updated"
        );
        break;
      case "Declaration":
        typeInfo = this.languageService.getValue(
          "static.supplier.history_supplier.Declaration_Updated"
        );
        break;
      default:
        typeInfo = "";
        break;
    }
    return typeInfo;
  }

  load() {
    if (this.linkedList.nodes) {
      this.linkedList.nodes.forEach((node: IListNode<T>) => {
        const historyItem: IHistoryItem = {};
        historyItem.txId = node.hash;
        historyItem.date = node.createdOn;
        historyItem.supplierUser = node.createdBy;
        historyItem.show = false;
        historyItem.value = this.linkedList.compare(node, this.historyLabel);
        const formType = historyItem.value[0]
          ? historyItem.value[0].formType
          : "";
        if (historyItem.value.length > 0) {
          if (node.previous_hash) {
            if (historyItem.value[0].status === "Updated") {
              historyItem.IsUpdate = true;
            } else {
              historyItem.IsUpdate = false;
            }
            const status = environment.languageSwitch
              ? this.statusLanguage(historyItem.value[0].status)
              : historyItem.value[0].status;
            historyItem.typeInfo = this.fieldOrder(
              historyItem.typeInfo,
              historyItem.value[0].status,
              formType
            );
            historyItem.type = status + " " + this.By;
          } else {
            historyItem.IsUpdate = false;
            historyItem.typeInfo = this.fieldOrder_created(
              historyItem.typeInfo,
              formType
            );
            historyItem.type = this.Created_By;
          }
          this.nodes.push(historyItem);
        }
      });
    }
    this.nodes.sort(
      (a: any, b: any) =>
        moment(b.date).toDate().getTime() - moment(a.date).toDate().getTime()
    );
    return this.nodes;
  }
}
