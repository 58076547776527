import {
  Component,
  OnInit,
  OnChanges,
  OnDestroy,
  Input,
  HostListener,
  AfterViewInit,
} from "@angular/core";
import { ComponentConfig, ValidatorMap, ErrorKey } from "../../ComponentConfig";
import { UntypedFormGroup } from "@angular/forms";
import { DynamicContext } from "../../interfaces/DynamicContext";
import { Subscription } from "rxjs";
import { Formator } from "../../formators/Formator";
import { distinctUntilChanged, debounceTime } from "rxjs/operators";
import { Datasources } from "../../datasource/Datasources";

@Component({
  selector: "app-base",
  template: "<div></div>",
})
export class BaseComponent
  implements OnInit, OnChanges, OnDestroy, AfterViewInit
{
  @Input() config: ComponentConfig;
  @Input() context: DynamicContext;
  @Input() formGroup: UntypedFormGroup;
  formator: Formator;

  subscriptions: Subscription[] = [];
  defaultErrorMessageList: any = {};
  constructor() {}

  ngOnInit() {
    this.loadDatasource();
    this.initErrorMessage();
    this.context.on(`DataSourceLoaded:${this.config.name}`, (ds) => {
      this.onDatasourceLoaded(ds);
    });
  }

  initErrorMessage() {
    this.defaultErrorMessageList["required"] = "please input";
    this.defaultErrorMessageList[
      "email"
    ] = `${this.config.label} email format check`;
  }

  ngAfterViewInit(): void {}

  loadDatasource() {
    if (!this.config.options && this.config.dataSource) {
      Datasources.loadData(this.config, this.context, this.formGroup).then(
        (ds) => this.onDatasourceLoaded(ds)
      );
    }
  }

  onDatasourceLoaded(ds: any[]) {
    this.config.options = ds;
  }

  set value(v) {
    this.formGroup.controls[this.config.name].patchValue(v, {
      onlySelf: true,
      emitEvent: true,
    });
  }

  get value() {
    if (!this.config.name) {
      return;
    }
    let v = this.formGroup.controls[this.config.name].value;
    if (this.formator) {
      v = this.formator.format(v);
    }
    if(this.config.name == 'SignedQuotationAttachments' && this.formGroup.controls[this.config.name].value == '') {
      this.formGroup.controls[this.config.name].setValue(null) ;
    }
    return v;
  }

  get control() {
    return this.formGroup.controls[this.config.name];
  }

  get error() {
    return this.formGroup.controls[this.config.name].errors;
  }

  public errorMessage(controlName: any) {
    if (controlName) {
      if (this.formGroup.get(this.config.name).get(controlName).errors) {
        const key = Object.keys(
          this.formGroup.get(this.config.name).get(controlName).errors
        )[0];
        for (const control of this.config.fieldset) {
          if (control.name == controlName) {
            if (control.errorMessage && control.errorMessage[key]) {
              return control.errorMessage[key];
            } else {
              return this.defaultErrorMessageList[key];
            }
          }
        }
      }
    }
    if (this.error) {
      const key = Object.keys(this.error)[Object.keys(this.error).length - 1];
      if (this.config.errorMessage && this.config.errorMessage[key]) {
        return this.config.errorMessage[key];
      } else {
        return this.defaultErrorMessageList[key];
      }
    }
    return "";
  }

  public hasError(controlName: any) {
    let submitState: boolean;
    if (this.context.parent) {
      submitState = this.context.parent.SubmitState;
    } else {
      submitState = this.context.SubmitState;
    }
    if (controlName) {
      return (
        this.formGroup.get(this.config.name).get(controlName).errors &&
        submitState
      );
    } else if (
      this.config.validateOn === "change" &&
      this.formGroup.get(this.config.name).dirty
    ) {
      return this.formGroup.get(this.config.name).errors;
    } else {
      return (
        (this.formGroup.get(this.config.name).errors && submitState) ||
        (this.formGroup.get(this.config.name).errors &&
          this.config.validateOn === "blur" &&
          (this.formGroup.controls[this.config.name].touched ||
            this.formGroup.controls[this.config.name].dirty))
      );
    }
  }

  public isWarning(): boolean {
    if (this.error) {
      return Object.values(this.error)[0] === false;
    }
    return false;
  }

  public subscribeToValueChange(
    name: string,
    fn: Function,
    runBeforeSub = false
  ) {
    if (runBeforeSub) {
      fn(this.formGroup.get(name).value);
    }
    const sub = this.formGroup
      .get(name)
      .valueChanges.pipe(
        // debounceTime(400),
        distinctUntilChanged()
      )
      .subscribe((v) => {
        fn(v);
      });
    this.subscriptions.push(sub);
  }

  ngOnDestroy(): void {
    if (this.subscriptions && this.subscriptions.length > 0) {
      this.subscriptions.forEach((s) => s.unsubscribe());
    }
  }

  ngOnChanges() {
    console.log(`base component ngOnChanges`);
  }

  onClick() {}

  @HostListener("click", ["$event"])
  handleEvent(event: Event) {}

  display() {
    this.config.hide = false;
  }

  hide() {
    this.config.hide = true;
  }

  toggle() {
    this.config.hide = !this.config.hide;
  }

  disable() {
    this.formGroup.get(this.config.name).disable();
  }

  enable() {
    this.formGroup.get(this.config.name).enable();
  }

  resetComponent() {}
}
