import { Injectable } from "@angular/core";
import { HttpService } from "./api/http.service";
import { environment } from "../../environments/environment";
import { Task, HistoryTask, AllTaskKey } from "../interfaces/workflow";
import * as moment from "moment";
import { SupplierModel } from "../interfaces/supplierModel";
@Injectable()
export class WorkflowService {
  baseUrl = environment.gateway;
  userTasks: Array<Task> = [];
  constructor(private http: HttpService) {}

  // get execute task
  getExecuteTask(
    processKey: string,
    taskKey: string,
    variables: any = {}
  ): Task {
    const executeTask = this.getTaskByKey(processKey, taskKey, this.userTasks);
    executeTask.variables = variables;
    return executeTask;
  }

  async loadUserTasks(
    role: string,
    supplierCode: string
  ): Promise<Array<Task>> {
    this.userTasks = await this.getMyActiveTasks(supplierCode, role);
    return this.userTasks;
  }

  // get active task by processKey and taskKey
  getTaskByKey(
    processKey: string,
    taskKey: string,
    activeTasks: Array<Task>
  ): Task {
    let task: Task;
    if (!activeTasks) {
      return undefined;
    }
    for (const t of activeTasks) {
      if (t.name === taskKey && t.processDefName === processKey) {
        task = t;
        break;
      }
    }
    return task;
  }

  // get active task by task key
  getTaskByTaskKey(taskKey: string, activeTasks: Array<Task>): Task {
    let task: any;
    if (!activeTasks) {
      return undefined;
    }
    for (const t of activeTasks) {
      if (t.name === taskKey) {
        task = t;
        break;
      }
    }
    return task;
  }
  // Does not include AP sub process
  checkTaskIsComplete(
    processKey: string,
    taskKey: string,
    historyTasks: Array<HistoryTask>
  ) {
    if (!historyTasks) {
      return false;
    }
    for (const t of historyTasks) {
      if (t.name === taskKey && t.processDefName === processKey) {
        return true;
      }
    }
    return false;
  }
  private getAPTasks(
    processKey: string,
    taskKey: string,
    historyTasks: Array<HistoryTask>
  ): Array<HistoryTask> {
    if (!historyTasks || historyTasks.length < 1) {
      return undefined;
    }
    const AP_Tasks = historyTasks
      .filter((t) => t.processDefName === processKey && taskKey === t.name)
      .sort(
        (first, second) =>
          moment(first.endTime).unix() - moment(second.endTime).unix()
      );
    if (AP_Tasks.length < 1) {
      return undefined;
    }
    return AP_Tasks;
  }

  // check ap approve
  checkAPTaskIsComplete(
    processKey: string,
    taskKey: string,
    historyTasks: Array<HistoryTask>
  ) {
    const ap_tasks = this.getAPTasks(processKey, taskKey, historyTasks);
    if (!ap_tasks) {
      return false;
    }
    const task = ap_tasks[ap_tasks.length - 1];
    if (
      taskKey === AllTaskKey.SST_review &&
      task.variablesObj &&
      task.variablesObj.SST_Approve
    ) {
      return true;
    } else if (
      taskKey === AllTaskKey.QC_review &&
      task.variablesObj &&
      task.variablesObj.QC_Approve
    ) {
      return true;
    } else {
      return false;
    }
  }

  // check ap reject
  checkAPTaskPendingReview(processKey: string,
    taskKey: string,
    historyTasks: Array<HistoryTask>)
  {
  if (!historyTasks || historyTasks.length < 1) {
    return false;
  }

  const ap_tasks = historyTasks
      .sort(
        (first, second) =>
          moment(first.endTime).unix() - moment(second.endTime).unix()
      ); 
  
    if (!ap_tasks) {
      return false;
    }
    const task = ap_tasks[ap_tasks.length - 1];
    if (
      task.processDefName ==  processKey &&
      task.name == taskKey
    )
    {
      return true;
    }
    else {
      return false;
    }
  }

  checkAPTaskIsReject(
    processKey: string,
    taskKey: string,
    historyTasks: Array<HistoryTask>
  ) {
    const ap_tasks = this.getAPTasks(processKey, taskKey, historyTasks);
    if (!ap_tasks) {
      return false;
    }
    const task = ap_tasks[ap_tasks.length - 1];
    if (
      taskKey === AllTaskKey.SST_review &&
      task.variablesObj &&
      task.variablesObj.SST_RejectTo
    ) {
      return true;
    } else if (
      taskKey === AllTaskKey.QC_review &&
      task.variablesObj &&
      task.variablesObj.QC_Reject
    ) {
      return true;
    } else {
      return false;
    }
  }

  // get user's task by groupName and userName
  public async getUserTasksByGroup(
    groupNm: string,
    supplierCode: string
  ): Promise<Array<Task>> {
    let userTasks: Array<Task>;
    const data = await this.getTaskByGroup(groupNm, supplierCode);
    if (data.isSuccess) {
      userTasks = data.data;
    }
    return userTasks;
  }

  public async getUserTasksByUser(supplierCode: string): Promise<Array<Task>> {
    let userTasks: Array<Task>;
    const data = await this.getTaskByUser(supplierCode);
    if (data.isSuccess) {
      userTasks = data.data;
    }
    return userTasks;
  }

  public async startProcess(
    supplierCode: string,
    processName: string
  ): Promise<any> {
    const url = `${this.baseUrl}/workflow/start/process/${supplierCode}/${processName}`;
    return this.http.GetPromise(url);
  }

  public async getMyActiveTasks(
    supplierCode: string,
    role: string
  ): Promise<any> {
    const url =
      `${this.baseUrl}/workflow/myActiveTasks/${supplierCode}/${role}` +
      "?num=" +
      Math.random();
    const data = (await this.http.GetPromise(url)) as any;
    if (data.isSuccess) {
      return data.data;
    }
    return [];
  }

  public async getTaskByUser(supplierCode: string): Promise<any> {
    const url =
      `${this.baseUrl}/workflow/getTaskByUser/${supplierCode}` +
      "?num=" +
      Math.random();
    return this.http.GetPromise(url);
  }

  public async getTaskByGroup(
    groupNm: string,
    supplierCode: string
  ): Promise<any> {
    groupNm = groupNm == 'systemAdmin' ? 'buyerAdmin' : groupNm

    const url =
      `${this.baseUrl}/workflow/getTaskByGroup/${groupNm}/${supplierCode}` +
      "?num=" +
      Math.random();
    return this.http.GetPromise(url);
  }

  public async getActiveTaskByBusinessKey(supplierCode: string): Promise<any> {
    const url = `${this.baseUrl}/workflow/get/all/task/${supplierCode}`;
    return this.http.GetPromise(url);
  }

  public async getTaskHistoriesByBusinessKey(
    supplierCode: string
  ): Promise<any> {
    const url = `${this.baseUrl}/workflow/get/all/task/histories/${supplierCode}`;
    return this.http.GetPromise(url);
  }

  public async getProcessHistoriesByBusinessKey(
    supplierCode: string
  ): Promise<any> {
    const url = `${this.baseUrl}/workflow/get/all/process/histories/${supplierCode}`;
    return this.http.GetPromise(url);
  }

  public async getProcessInstanceByBusinessKey(
    supplierCode: string
  ): Promise<any> {
    const url = `${this.baseUrl}/workflow/get/allActive/process/${supplierCode}`;
    return this.http.GetPromise(url);
  }
}
